import React, {useContext, useState} from 'react';

import '../styles/NavPanel.css'
import {Context} from "../index";
import {setCookie} from "../utils/cookies";
import {URLS} from "../utils/urls";
import {observer} from "mobx-react-lite";

const homeIco = require('../assets/icons/home.png')
const homeActiveIco = require('../assets/icons/homeActive.png')
const adminIco = require('../assets/icons/admin.png')
const adminActiveIco = require('../assets/icons/adminActive.png')
const companiesIco = require('../assets/icons/companiesSell.png')
const suppliersIco = require('../assets/icons/companiesBuy.png')
const companiesActiveIco = require('../assets/icons/companiesActive.png')
const profileIco = require('../assets/icons/profile.png')
const profileActiveIco = require('../assets/icons/profileActive.png')
const logoutIco = require('../assets/icons/logout.png')
const logoutActiveIco = require('../assets/icons/logoutActive.png')



function mouseDownImageHandler(setState: React.Dispatch<React.SetStateAction<boolean>>) { setState(true) }
function mouseUpImageHandler(setState: React.Dispatch<React.SetStateAction<boolean>>) { setState(false) }


const NavPanel = observer(() => {
    const { user } = useContext(Context)

    const userRole: 'USER' | 'SUPERUSER' = user.getUser.role;

    const [isHomeClicked, setIsHomeClicked] = useState(false)
    const [isCompClicked, setIsCompClicked] = useState(false)
    const [isSupplierClicked, setIsSupplierClicked] = useState(false)
    const [isProfileClicked, setIsProfileClicked] = useState(false)
    const [isAdminClicked, setIsAdminClicked] = useState(false)
    const [isLogoutClicked, setIsLogoutClicked] = useState(false)

    function logout() {
        setCookie('username', '', -365);
        setCookie('password', '', -365);
        user.setIsAuth(false);
        user.setUser({});
        localStorage.removeItem('token');
    }

    return (
        <div>
            <div className='navIndent'></div>
            <nav className='navPanel'>
                <div className='navPanel__top-items'>
                    <a href={URLS.MAIN_URL} className='navPanel__ref'>
                        <img className='navPanel__image'
                             src={isHomeClicked ? homeActiveIco : homeIco} alt="Главная"
                             onMouseDown={() => {
                                 mouseDownImageHandler(setIsHomeClicked)
                             }}
                             onMouseUp={() => {
                                 mouseUpImageHandler(setIsHomeClicked)
                             }}
                        />
                    </a>
                    <a href={URLS.COMPANIES_URL} className='navPanel__ref'>
                        <img className='navPanel__image'
                             src={isCompClicked ? companiesActiveIco : companiesIco} alt="Предприятия"
                             onMouseDown={() => {
                                 mouseDownImageHandler(setIsCompClicked)
                             }}
                             onMouseUp={() => {
                                 mouseUpImageHandler(setIsCompClicked)
                             }}
                        />
                    </a>
                    <a href={URLS.SUPPLIERS_URL} className='navPanel__ref'>
                        <img className='navPanel__image'
                             src={isSupplierClicked ? companiesActiveIco : suppliersIco} alt="Поставщики"
                             onMouseDown={() => {
                                 mouseDownImageHandler(setIsSupplierClicked)
                             }}
                             onMouseUp={() => {
                                 mouseUpImageHandler(setIsSupplierClicked)
                             }}
                        />
                    </a>
                    <a href={URLS.PROFILE_URL} className='navPanel__ref'>
                        <img className='navPanel__image'
                             src={isProfileClicked ? profileActiveIco : profileIco} alt="Профиль"
                             onMouseDown={() => {
                                 mouseDownImageHandler(setIsProfileClicked)
                             }}
                             onMouseUp={() => {
                                 mouseUpImageHandler(setIsProfileClicked)
                             }}
                        />
                    </a>
                    {
                        userRole === 'SUPERUSER' ?
                            <a href={URLS.ADMIN_URL} className='navPanel__ref'>
                                <img className='navPanel__image'
                                     src={isAdminClicked ? adminActiveIco : adminIco} alt="Администрирование"
                                     onMouseDown={() => {
                                         mouseDownImageHandler(setIsAdminClicked)
                                     }}
                                     onMouseUp={() => {
                                         mouseUpImageHandler(setIsAdminClicked)
                                     }}
                                />
                            </a>
                            :
                            null
                    }
                </div>
                <div className='navPanel__bot-items'>
                    {/*<a className='navPanel__ref'>*/}
                        <img className='navPanel__image'
                             src={isLogoutClicked ? logoutActiveIco : logoutIco} alt="Выйти"
                             onMouseDown={() => {
                                 mouseDownImageHandler(setIsLogoutClicked)
                             }}
                             onMouseUp={() => {
                                 mouseUpImageHandler(setIsLogoutClicked)
                                 logout()
                             }}
                        />
                    {/*</a>*/}
                </div>
            </nav>
        </div>
    );
});


export default NavPanel;