import React, {useContext} from 'react';
import '../styles/AuthPage.css'
import '../styles/vars.css'
import { setCookie } from "../utils/cookies";
import { loginApi } from "../http/userApi";
import {Context} from "../index";
const jwt_decode = require("jwt-decode");


const Auth = () => {
    const { user } = useContext(Context)

    async function onBtnClick(e: any) {
        e.preventDefault();
        const { username, password, memorizeMe } = e.target;

        if (memorizeMe.checked === true) {
            setCookie('username', username.value, 365);
            setCookie('password', password.value, 365);
        } else {
            setCookie('username', '', -365);
            setCookie('password', '', -365);
        }

        const res = await loginApi({
            username: username.value, password: password.value });
        
        if (res.response){
            if (res.response.status >= 400) {
                addMessage(res.response.data.message, 'red');
            }
        } else if (res.data) {
            if (res.status === 200) {
                addMessage(res.data.message, 'green');
                user.setIsAuth(true)
                user.setUser(jwt_decode.jwtDecode(res.data.token));
            }
        }

    }


    return (
        <div className="auth">
            <div className='auth__auth-form-container'>
                <div className='auth__body'>
                    <form className='auth__form' onSubmit={onBtnClick}>
                        <span id='authMsg'></span>
                        <input id='username'

                               required={true} className='auth__form-login auth__form-input' type="text"
                               autoComplete="on"
                               placeholder='Логін'
                               maxLength={24}/>
                        <input id='password'

                               required={true} className='auth__form-password auth__form-input'
                               type="password"
                               autoComplete="on"
                               placeholder='Пароль' maxLength={24}/>
                        <div className='auth__mmrzMe-container'>
                            <label className='auth__mmrzMe-label'>
                                <input id='memorizeMe' type='checkbox'/>
                                <span>Запомнить меня</span>
                            </label>
                        </div>
                        <button className='auth__form-button' type='submit'>Войти</button>
                    </form>
                </div>
            </div>
        </div>
    );
};


export default Auth;


function addMessage(message: string, color: string) {
    let msg: HTMLElement | null = document.getElementById('authMsg');
    if (msg) {
        msg.innerHTML = message;

        switch (color) {
            case 'red':
                msg.style.color = '#FF5858FF'
                break
            case 'green':
                msg.style.color = '#85FF85FF'
                break
        }
    }
}
