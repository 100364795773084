import { DOMAIN_NAME } from "../config";


function getCookie(...SearchVal: string[]) {
    const searchValSet: Set<string> = new Set(SearchVal);
    const resultObj: { [key: string]: string } = {};

    let found: boolean = false;

    const cookies: Array<string> = document.cookie.split(';');
    cookies.forEach((c: string) => {
        const [key, value] = c.replaceAll(' ', '').split('=');
        if (searchValSet.has(key)){
            found = true
            resultObj[key] = value
        }
    });

    if (found) {
        return resultObj;
    }
    return null;
}

function setCookie(cName: string, cValue: string, exDays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cName + "=" + cValue + ";" + expires + `;domain=.${DOMAIN_NAME}; path=/; secure;`;
}

export {
    getCookie,
    setCookie
}