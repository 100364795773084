import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from "./store/UserStore";
import WsStore from "./store/WsStore";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const Context = createContext<any>(null);

root.render(
    // <React.StrictMode>

    <Context.Provider value={{
        user: new UserStore(),
        ws: new WsStore(),
    }}>

        <App />

    </Context.Provider>

    // </React.StrictMode>
);
