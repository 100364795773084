export function addMessageIntoHtml(
    params: {
        elementId: string,
        message: string,
        msgColor: 'green' | 'red' | 'orange' | 'white',
        hideElemInSec: false | number,
    }) {

    const color = {
        'green': '#85FF85FF',
        'red': '#FF5858FF',
        'orange': '#FAC768FF',
        'white': '#e9e9e9',
        'black': '#000000',
    }

    const msgItem = document.getElementById(params.elementId);
    if (msgItem) {
        msgItem.style.color = color[params.msgColor];
        msgItem.innerText = params.message;

        if (params.hideElemInSec) {
            setTimeout(() => {
                msgItem.innerText = '';
            }, params.hideElemInSec * 1000);
        }
    }
}

export const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}