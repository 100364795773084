import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";


type CompanyType = {
    name: string;
    code: string | null;
    country: string | null;
    city: string | null;
    region: string | null;
    address: string | null;
    curatorId: number | null;
}


export async function companyGetAPI(params?: { companyId: number | string }){
    try {
        if (params && params.companyId) {
            return await $authHost.get(API_ENDPOINTS.COMPANY + '/' + params.companyId);
        } else {
            return await $authHost.get(API_ENDPOINTS.COMPANY);
        }

    } catch (error: any) {
        return error;
    }
}

export async function companyAddNewAPI(data: any){
    try {
        return await $authHost.post(API_ENDPOINTS.COMPANY, data);

    } catch (error: any) {
        return error;
    }
}

export async function companySearchAPI(query: string){
    try {
        return await $authHost.get(API_ENDPOINTS.COMPANY + `/search${query}`);

    } catch (error: any) {
        return error;
    }
}

export async function companyUpdateAPI(props: {id: string | number, data: any}){
    try {
        return await $authHost.put(API_ENDPOINTS.COMPANY + `/` + props.id, props.data);

    } catch (error: any) {
        return error;
    }
}

export async function companyDeleteAPI(id: string | number){
    try {
        return await $authHost.delete(API_ENDPOINTS.COMPANY + `/` + id);

    } catch (error: any) {
        return error;
    }
}

export async function companyRepresentativeAddAPI(data: any){
    try {
        return await $authHost.post(API_ENDPOINTS.REPRESENTATIVE, data);

    } catch (error: any) {
        return error;
    }
}

export async function companyRepresentativeDeleteAPI(id: string | number){
    try {
        return await $authHost.delete(API_ENDPOINTS.REPRESENTATIVE + `/` + id);

    } catch (error: any) {
        return error;
    }
}

export async function companyRepresentativeUpdateAPI(id: string | number, data: any){
    try {
        return await $authHost.put(API_ENDPOINTS.REPRESENTATIVE + `/` + id, data);

    } catch (error: any) {
        return error;
    }
}
