import {URLS} from './utils/urls';

import Admin from './pages/Admin';
import Auth from './pages/Auth';
import Companies from './pages/Companies';
import SelectedCompany from './pages/SelectedCompany';
import Main from './pages/Main';
import Profile from './pages/Profile';

import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import {getUserHighestRole} from "./utils/userRoles";
import Suppliers from './pages/Suppliers';
import SelectedSupplier from './pages/SelectedSupplier';


const routesObj: { [key: string]: JSX.Element } = {
    'admin': <Routes>
                    <Route key={URLS.ADMIN_URL} path={URLS.ADMIN_URL} Component={ Admin }/>
                    <Route key={URLS.MAIN_URL} path={URLS.MAIN_URL} Component={ Main }/>
                    <Route key={URLS.COMPANIES_URL} path={URLS.COMPANIES_URL} Component={ Companies }/>
                    <Route key={URLS.SUPPLIERS_URL} path={URLS.SUPPLIERS_URL} Component={ Suppliers }/>
                    <Route key={URLS.COMPANY_URL} path={URLS.COMPANY_URL} Component={ SelectedCompany }/>
                    <Route key={URLS.SUPPLIER_URL} path={URLS.SUPPLIER_URL} Component={ SelectedSupplier }/>
                    <Route key={URLS.PROFILE_URL} path={URLS.PROFILE_URL} Component={ Profile }/>
                    <Route path="*" element={ <Navigate to={URLS.MAIN_URL} /> }/>
            </Routes>,

    'auth': <Routes>
                    <Route key={URLS.MAIN_URL} path={URLS.MAIN_URL} Component={ Main }/>
                    <Route key={URLS.COMPANIES_URL} path={URLS.COMPANIES_URL} Component={ Companies }/>
                    <Route key={URLS.SUPPLIERS_URL} path={URLS.SUPPLIERS_URL} Component={ Suppliers }/>
                    <Route key={URLS.COMPANY_URL} path={URLS.COMPANY_URL} Component={ SelectedCompany }/>
                    <Route key={URLS.SUPPLIER_URL} path={URLS.SUPPLIER_URL} Component={ SelectedSupplier }/>
                    <Route key={URLS.PROFILE_URL} path={URLS.PROFILE_URL} Component={ Profile }/>
                    <Route path="*" element={ <Navigate to={URLS.MAIN_URL} /> }/>
            </Routes>,
    'none':
            <Routes>
                <Route key={URLS.LOGIN_URL} path={URLS.LOGIN_URL} Component={ Auth }/>
                <Route path="*" element={ <Navigate to={URLS.LOGIN_URL} /> }/>
            </Routes>,

}

export function getRoutes() {
    const role: 'none' | 'auth' | 'admin' | undefined = getUserHighestRole();

    if (role) {
        return routesObj[role];
    }

    return routesObj['none']
}