export const URLS = {
    REGISTRATION_URL: '/registration',
    LOGIN_URL: '/login',
    CHECK: '/check',

    MAIN_URL: '/',
    ADMIN_URL: '/admin',
    PROFILE_URL: '/profile',
    COMPANIES_URL: '/companies',
    COMPANY_URL: '/company/:id',
    SUPPLIERS_URL: '/suppliers',
    SUPPLIER_URL: '/supplier/:id',
}

export const API_ENDPOINTS = {
    USER: 'api/v1/user',
    PERMISSIONS: 'api/v1/user/permissions',
    CURRENT_USER: 'api/v1/user/current-user',
    COMPANY: 'api/v1/company',
    REPRESENTATIVE: 'api/v1/company/representative',
    ACT_HISTORY: 'api/v1/action-history',
    COMP_HISTORY: 'api/v1/company-history',
    TASK: 'api/v1/task',
    CALCULATION: 'api/v1/calculation',
    PDF: 'api/v1/pdf',
    SUPPLIER: 'api/v1/supplier',
    SUPPLIER_REPR: 'api/v1/supplier/representative',
    SUPPLIER_HISTORY: 'api/v1/supplier-history',
}

