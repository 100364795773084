import {makeAutoObservable} from "mobx";

export default class UserStore{
    private _isAuth = false;
    private _user = {};
    private _permissions: Set<string> = new Set();

    constructor() {
        makeAutoObservable(this);

    };

    setIsAuth(bool: boolean){
        this._isAuth = bool;
    }

    setUser(user: {}){
        this._user = user;
    }

    setPerms(perms: Set<string>) {
        this._permissions = perms;
    }

    get getIsAuth(){
        return this._isAuth;
    }

    get getUser(){
        return this._user;
    }

    get getPermissions() {
        return this._permissions;
    }

}