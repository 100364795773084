import React, {useContext, useEffect, useState} from 'react';
import NavPanel from "../components/NavPanel";
import '../styles/Profile.css';
import {Context} from "../index";
import {userCurrentGetApi, userCurrentPutApi} from "../http/userApi";
import {setResponseMessage} from "../utils/responseInform";


type UserType = {
    username: string,
    password?: string,
    firstName: string | null,
    lastName: string | null,
    middleName: string | null,
    phone: string | null,
}


const Profile = () => {
    const { user } = useContext(Context);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editBtn, setEditBtn] = useState<JSX.Element>(<></>);
    const [userData, setUserData] = useState<UserType | null>(null);

    useEffect(() => {

        if (isEdit) {
            setEditBtn(
                <div>
                    <button form='userForm' className='profile__save-button nav__button'
                            type='submit'>Сохранить
                    </button>
                    <button className='profile__cancel-button nav__button'
                            onClick={() => {
                                setIsEdit(false)
                            }}
                    >Отмена
                    </button>
                </div>
            )
        } else {
            setEditBtn(
                <button className='profile__nav-button nav__button'
                        onClick={() => {
                            setIsEdit(true)
                        }}
                >Редактировать</button>
            )
        }
    }, [isEdit]);

    useEffect(() => {
        userCurrentGetApi()
            .then((response) => {
                if (response.data) {
                    setUserData(response.data)
                }
            }).catch((err) => {console.log(err)})
    }, []);

    return (
        <div className='profile page-content-container'>
            <NavPanel/>
            <div className='profile_body page_content_body'>
                <div className="profile__content-container contentWindow">
                    <div className="profile__data-header contentWindow__header">
                        <span className='contentWindow__name'>Профиль</span>
                    </div>
                    <div className="profile__data-nav contentWindow__nav">
                        { editBtn }
                    </div>
                    <div className="profile__data-body-user-info contentWindow__body">
                        { userData ?
                            isEdit ?
                                userEditable(userData, setIsEdit, setUserData)
                                :
                                userTable(userData)
                            : null }
                    </div>
                </div>
            </div>
        </div>
    );
};


function userTable(userData: UserType) {

    return (
        <table className='profile__table'>
            <tbody className='profile__tbody'>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Логин</td>
                        <td className='profile__td'>{ userData.username }</td>
                    </tr>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Пароль</td>
                        <td className='profile__td'>********</td>
                    </tr>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Имя</td>
                        <td className='profile__td'>{ userData.firstName }</td>
                    </tr>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Фамилия</td>
                        <td className='profile__td'>{ userData.lastName }</td>
                    </tr>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Отчество</td>
                        <td className='profile__td'>{ userData.middleName }</td>
                    </tr>
                    <tr className='profile__tr'>
                        <td className='profile__td profile__td-static'>Телефон</td>
                        <td className='profile__td'>{ userData.phone }</td>
                    </tr>
            </tbody>
        </table>
    )
}

function userEditable(userData: UserType,
                      setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
                      setUserData: React.Dispatch<React.SetStateAction<UserType | null>>
) {

    return (
        <form id='userForm' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            onSubmit={(e) => {
                e.preventDefault()
                const handle = handleSaveData(e);
                if (handle) {
                    handle.then((response) => {
                        if (response.response && response.response.status >= 400){
                            setResponseMessage(`${response.response.data.message}`, 'error', 'errMsg')
                        } else if (response.data){
                            setUserData(response.data);
                            setIsEdit(false);
                        }
                    }).catch((err) => {
                        console.log(err) })
                }
            }}
        >
            <table className='profile__table'>
                <tbody className='profile__tbody'>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Логин</td>
                    <td className='profile__td'>
                        <input id='username' name='username' type="text" placeholder='Логин*' required={true}
                               className='admin__input-field' maxLength={64}
                               defaultValue={userData.username}
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Пароль</td>
                    <td className='profile__td'>
                        <input id='password' name='password' type="text" placeholder='Пароль*' required={true}
                               className='admin__input-field' maxLength={64}
                               defaultValue='********'
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Имя</td>
                    <td className='profile__td'>
                        <input id='firstName' name='firstName' type="text" placeholder='Имя'
                               className='admin__input-field' maxLength={64}
                               defaultValue={userData.firstName !== null ? userData.firstName : ''}
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Фамилия</td>
                    <td className='profile__td'>
                        <input id='lastName' name='lastName' type="text" placeholder='Фамилия'
                               className='admin__input-field' maxLength={64}
                               defaultValue={userData.lastName !== null ? userData.lastName : ''}
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Отчество</td>
                    <td className='profile__td'>
                        <input id='middleName' name='middleName' type="text" placeholder='Отчество'
                               className='admin__input-field' maxLength={64}
                               defaultValue={userData.middleName !== null ? userData.middleName : ''}
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__td profile__td-static'>Телефон</td>
                    <td className='profile__td'>
                        <input id='phone' name='phone' type="text" placeholder='Телефон'
                               className='admin__input-field' maxLength={64}
                               defaultValue={userData.phone !== null ? userData.phone : ''}
                        />
                    </td>
                </tr>
                <tr className='profile__tr'>
                    <td className='profile__err-field' colSpan={2}>
                        <span id='errMsg'></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )
}

function handleSaveData(event: any) {
    const { username, password, firstName, lastName, middleName, phone } = event.target;

    if (password.value.length < 8) {
        setResponseMessage('Пароль должен быть больше 8 символов', 'error', 'errMsg')
        return
    }

    const newUserData: UserType = {
        username: username.value,
        password: password.value,
        firstName: firstName.value.length > 0 ? firstName.value : null,
        lastName: lastName.value.length > 0 ? lastName.value : null,
        middleName: middleName.value.length > 0 ? middleName.value : null,
        phone: phone.value.length > 0 ? phone.value : null,
    }

    return userCurrentPutApi(newUserData);
}


export default Profile;