import axios from 'axios';
import {AxiosInstance} from "axios";


import {REACT_APP_API_URL} from "../config";


const $host: AxiosInstance = axios.create({
    baseURL: REACT_APP_API_URL
})

const $authHost: AxiosInstance = axios.create({
    baseURL: REACT_APP_API_URL
})


const authInterceptor = (config: any) => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost
}