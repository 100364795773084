import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { supplierAddNewAPI, supplierGetAPI, supplierSearchAPI } from "../http/supplierAPI";
import {API_ENDPOINTS, URLS} from "../utils/urls";

import NavPanel from "../components/NavPanel";
import Popup from '../components/Popup';

import '../styles/Companies.css';
import {setResponseMessage} from "../utils/responseInform";


type SupplierType = {
    id: number;
    name: string;
    code: string;
    country: string;
    city: string;
    region: string;
    address: string;
    curatorId: number;
    curatorName?: string;
}


const Suppliers = observer(() => {
    const { user, ws } = useContext(Context);
    const [perms, setPerms] = useState<Set<string>>(new Set());
    const [newCompBtn, setNewCompBtn] = useState<JSX.Element | null>(null)

    const [companiesData, setCompaniesData] = useState<Array<SupplierType> | null>(null);
    const [usersMap, setUsersMap] = useState<{[key: number]: string}>({})

    const [isPopup, setIsPopup] = useState<boolean>(false);

    const [lastSearchParams, setLastSearchParams] = useState({search: '', curator: 'all'});

    const handleCompaniesResponse = (params: {response: Array<SupplierType>, usersMap?: {[key: number]: string}}) => {
       
        const userIdToName = params.usersMap ? params.usersMap : usersMap;
        const newCompaniesData: Array<SupplierType> = [];
        params.response.forEach((c: SupplierType) => {
            c.curatorName = userIdToName[c.curatorId];

            newCompaniesData.push(c);
        });
        setCompaniesData(newCompaniesData);
    };

    const sendSearchRequest = (search: string, curators: string) => {

        const searchQ = search.length > 0 ? `search=${search.replaceAll(' ', '+')}&` : '';
        const curatorsQ = `curator=${curators}`;
        const query = `?${searchQ}${curatorsQ}`;

        setLastSearchParams({search: search, curator: curators});

        supplierSearchAPI(query)
            .then((response) => {
                if (response.data) {                    
                    handleCompaniesResponse({response: response.data});
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const message = ws.getMsg;
        
        if (message !== null) {
            switch (message) {
                case 'suppliers':
                    sendSearchRequest(lastSearchParams.search, lastSearchParams.curator);
                    ws.setMessage(null);
                    break
            }
        }

    }, [ws.getMsg]);

    useEffect(() => {
        setPerms(user.getPermissions.data_);
    }, [user.getPermissions]);


    useEffect(() => {
        if (perms.has('company.add')) {
            setNewCompBtn(<button className='companies__btn-add-company companies__btn'
                                  onClick={() => {
                                      setIsPopup(true)
                                  }}
                            >Добавить поставщика</button>);
        } else {setNewCompBtn(null)}
    }, [perms]);


    useEffect(() => {
        supplierGetAPI()
            .then((response) => {
                if (response.data) {                    
                    const newUsersMap = response.data.users;
                    
                    if (user.getUser.username !== 'superuser') {
                        delete newUsersMap[1]
                    }
                    
                    setUsersMap(newUsersMap);
                    handleCompaniesResponse({response: response.data.suppliers, usersMap: newUsersMap});
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    const popupBody = (
        <div className='companies__popup-body'>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <span id='msgField' style={{fontSize: '90%'}}></span>
            </div>
            <form id='popupForm' className='companies__popup-form' onSubmit={(ev: any) => {
                ev.preventDefault();
                const {name, code, country, city, region, address, curator} = ev.target;
                const newCompanyData = {
                    name: name.value,
                    code: code.value.length === 0 ? null : code.value,
                    country: country.value.length === 0 ? null : country.value,
                    city: city.value.length === 0 ? null : city.value,
                    region: region.value.length === 0 ? null : region.value,
                    address: address.value.length === 0 ? null : address.value,
                    curator: curator.value === 'null' ? null : parseInt(curator.value),
                };

                if (name.value.replaceAll(' ', '').length < 3 || name.value.length < 3) {
                    setResponseMessage('Наименование должно быть\n больше трех символов', 'error', 'msgField');
                    return
                }

                supplierAddNewAPI(newCompanyData)
                    .then((response) => {
                        if (response.status === 200) {
                            setResponseMessage(`Успешно созданно`, 'success', 'msgField');
                        } else if (response.response.status >= 400) {
                            setResponseMessage(`Возникла ошибка`, 'error', 'msgField');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }}>
                <table className='companies__popup-table'>
                    <tbody className='companies__popup-tbody'>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='name' required={true} type="text"
                                   maxLength={128} autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Наименование</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='code' type="text" maxLength={10}
                                   autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Код ЄДРПОУ</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='country' type="text" maxLength={128}
                                   autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Страна</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='city' type="text" maxLength={128}
                                   autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Город</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='region' type="text" maxLength={128}
                                   autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Область</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <input className='companies__popup-input' name='address' type="text" maxLength={128}
                                   autoComplete='off'/>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Адрес</td>
                    </tr>
                    <tr className='companies__popup-tr'>
                        <td className='companies__popup-td companies__popup-td-dynamic'>
                            <select className='companies__popup-select' name="curator" id="">
                                <option className='companies__popup-option' value="null">Не назначен</option>
                                {
                                    Object.entries(usersMap).map((u) =>
                                        <option key={`user${u[0]}`} className='companies__popup-option'
                                                value={`${u[0]}`}>{u[1]}</option>
                                    )
                                }
                            </select>
                        </td>
                        <td className='companies__popup-td companies__popup-td-static'>Куратор</td>
                    </tr>
                    </tbody>
                </table>
            </form>
            <div className='companies__tools-container'>
                <button className='companies__btn popup__btn-save' type='submit' form='popupForm'>Сохранить</button>
                <button className='companies__btn popup__btn-reset' type='reset' form='popupForm'>Сброс</button>
            </div>
        </div>
    )


    return (
        <div className='companies page-content-container'>
            <Popup
                isActive={isPopup}
                setIsActive={setIsPopup}
                headerName='Добавить поставщика'
                bodyDivElement={ popupBody }
            ></Popup>
            <NavPanel/>
            <div className='companies__body page_content_body'>
                <nav className="companies__data-nav-panel">

                    <form id='searchForm' className="companies__search-form" onSubmit={(e) => {
                        e.preventDefault();
                        const searchForm = (e.target as HTMLFormElement)
                        const { search, curators } = searchForm;
                        sendSearchRequest(search.value, curators.value);
                    }}>
                        <input type="search" name='search' placeholder="Поиск" required={true}
                               className="companies__search-input" autoComplete="off"/>
                        <button className="companies__search-btn" type='submit'>Поиск</button>

                        <select
                            className='companies__select'
                            name='curators' onChange={(e) => {
                            const searchForm = (e.target.form as HTMLFormElement)
                            const { search, curators } = searchForm;
                            sendSearchRequest(search.value, curators.value);
                        }}>
                            <option value="all">Все кураторы</option>
                            <option value="null">Без куратора</option>
                            {
                                Object.entries(usersMap).map((u) =>
                                    <option key={`usersOption${u[0]}`} value={`${u[0]}`}>{u[1]}</option>
                                )
                            }
                        </select>
                        <button
                            className='companies__btn companies__btn-reset'
                            type='reset' onClick={() => {
                            sendSearchRequest('', 'all');
                        }}>Сбросить</button>
                    </form>
                    { newCompBtn }

                </nav>
                <div className="companies__data-container">
                    <table className="companies__table">
                        <thead className="companies__thead">
                        <tr className="companies__tr companies__tr-head">
                            <td className="companies__td">Наименование</td>
                            <td className="companies__td">ЄДРПОУ</td>
                                <td className="companies__td">Страна</td>
                                <td className="companies__td">Город</td>
                                <td className="companies__td">Область</td>
                                <td className="companies__td">Адрес</td>
                                <td className="companies__td">Куратор</td>
                            </tr>
                        </thead>
                        <tbody className="companies__tbody">
                            {
                                companiesData ?
                                    companiesData.map((c: SupplierType) =>
                                        <tr key={ `comp${c.id}` }
                                            className="companies__tr companies__tr-body">
                                            <td className="companies__td">
                                                <a  className='companies__ref'
                                                    href={`${URLS.SUPPLIER_URL.replace(':id', `${c.id}`)}`}>{ c.name }</a>
                                            </td>
                                            <td className="companies__td">{ c.code }</td>
                                            <td className="companies__td">{ c.country }</td>
                                            <td className="companies__td">{ c.city }</td>
                                            <td className="companies__td">{ c.region }</td>
                                            <td className="companies__td">{ c.address }</td>
                                            <td className="companies__td">{ c.curatorName }</td>
                                        </tr>
                                    )
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});





export default Suppliers;