import React, {useContext, useEffect, useState} from 'react';

import $ from 'jquery';
import {pdfGetDefaultAPI, pdfManagerUpdateAPI, pdfUpdateHTMLAPI} from "../http/pdfAPI";
import {REACT_APP_API_URL} from "../config";
import {Context} from "../index";
import {WsTriggers} from "../WebsocketTriggers";



type PdfSettingsType = {
    manager: Array<{ name: string, phone: string, }>
}

type PdfDataType = {
    id: 1,
    logo: string,
    stamp: string,
    invoice: number,
    pdfStructure: null | string,
    pdfSettings: PdfSettingsType
    createdAt: string,
    updatedAt: string
}


// const opacityNone = '1';
// const borderNone = '1px solid black';
const opacityNone = '0';
const borderNone = '0'

const textShadow = '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000';


const fileFontSize = '16px'
const fileColor = 'black'
const fileBorder = '1px solid black';
const fileVerticalPadding = '0';
const fileHorizontalPadding = '38px';
const file100WidthWithPadding = `calc(100% - calc(${fileHorizontalPadding} * 2))`;

const fileFontCalibri = 'Calibri, sans-serif'
const fileFontComic = 'Comic Sans MS, cursive, sans-serif'
const fileFontArial = 'Arial, sans-serif'



const indentLow = <table style={{ width: file100WidthWithPadding, borderCollapse: 'collapse' }}>
    <tbody>
    <tr>
        <td style={{ backgroundColor: 'rgba(255,212,0,0.5)', color: 'black', border: borderNone, fontSize: '6px', opacity: opacityNone }}>indent</td>
    </tr>
    </tbody>
</table>
const indentMid = <table style={{ width: file100WidthWithPadding, borderCollapse: 'collapse' }}>
    <tbody>
    <tr>
        <td style={{ backgroundColor: 'rgba(255,115,0,0.5)', color: 'black', border: borderNone, fontSize: '8px', opacity: opacityNone }}>indent</td>
    </tr>
    </tbody>
</table>
const indentHigh = <table style={{ width: file100WidthWithPadding, borderCollapse: 'collapse' }}>
    <tbody>
    <tr>
        <td style={{ backgroundColor: 'rgba(255,0,0,0.5)', color: 'black', border: borderNone, fontSize: '10px', opacity: opacityNone }}>indent</td>
    </tr>
    </tbody>
</table>

const warningHeader = <table style={{ width: file100WidthWithPadding, borderCollapse: 'collapse' }}>
                                        <tbody>
                                            <tr>
            <td style={{
                border: fileBorder, textAlign: 'center', fontFamily: fileFontCalibri,
                fontSize: '11pt', padding: '0.3em 1em', fontWeight: '100'                                 

                                                 }}>
                                                    Увага! Оплата цього рахунку означає погодження з умовами поставки товарів. Повідомлення про оплату є
                                                    обов'язковим, в іншому випадку не гарантується наявність товарів на складі.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

const aboutUs = (props: { logo: string | undefined }) => {
    const logo = props.logo ?
        <img style={{width: '56.64px', height: '56.64px'}}
             src={REACT_APP_API_URL + 'media/' + props.logo} alt="="/>
        :
        null
    return (
        <table style={{fontFamily: fileFontComic, width: file100WidthWithPadding, borderCollapse: 'collapse',
            fontWeight: 'bold', fontSize: '11pt'
        }}>
            <tbody style={{  }}>
            <tr>
                <td style={{ border: borderNone, width: '10%', textAlign: 'center' }}></td>
                <td style={{ border: borderNone, width: '10%', textAlign: 'center' }}>{ logo }</td>
                <td style={{ border: borderNone, width: '60%', textAlign: 'center' }}>
                    <table style={{ borderCollapse: 'collapse'}}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '0px', color: 'red', textShadow: textShadow }}>&#10003;</td>
                                <td style={{ padding: '0px 0px 0px 0.5em', textAlign: 'left' }}
                                >Виробництво - вогнетривких та теплоізоляційних матеріалів.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0px', color: 'red', textShadow: textShadow }}>&#10003;</td>
                                <td style={{ padding: '0px 0px 0px 0.5em', textAlign: 'left' }}
                                >Будівництво, ремонт, монтаж - промислових печей та котлів.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0px', color: 'red', textShadow: textShadow }}>&#10003;</td>
                                <td style={{ padding: '0px 0px 0px 0.5em', textAlign: 'left' }}
                                >Офіційний - дистриб'ютор світових лідерів.</td>
                            </tr>
                        </tbody>
                    </table>

                </td>
                <td style={{border: borderNone, width: '5%', textAlign: 'center'}}></td>
                <td style={{border: borderNone, width: '5%', textAlign: 'center' }}></td>
            </tr>
            </tbody>
        </table>
    )
}

const siteRef = (
    <table style={{fontFamily: fileFontComic, width: file100WidthWithPadding, borderCollapse: 'collapse',
        fontWeight: 'bold', fontSize: '11pt' }}>
        <tbody>
            <tr id='siteTr'>
                <td style={{ width: '33.33%' }}></td>
                <td style={{ width: '33.33%' }}></td>
                <td style={{ width: '33.33%', textAlign: 'end' }}>
                    <span>Сайт - </span>
                    <span style={{ color: 'red', textShadow: textShadow, }}>www.refractory.uno</span>
                </td>
            </tr>
        </tbody>
    </table>
)

const mockProductsTable = (
    <table
        id='mockProductsTable'
        style={{ width: "calc(100% - 76px)", borderCollapse: "collapse" , fontFamily: fileFontArial, fontSize: '12px' }}>
        <tbody>
        <tr style={{ backgroundColor: 'rgb(185, 185, 185)', fontWeight: 'bold', textAlign: 'center' }}>
            <td style={{ border: fileBorder }}>№</td>
            <td style={{ border: fileBorder }}>Товари (роботи, послуги)</td>
            <td style={{ border: fileBorder }} colSpan={2}>Кількість</td>
            <td style={{ border: fileBorder }}>Ціна без ПДВ</td>
            <td style={{ border: fileBorder }}>Сума без ПДВ</td>
        </tr>
            <tr>
                <td style={{ border: fileBorder, textAlign: 'center' }}>1</td>
                <td style={{ border: fileBorder }}>Мертель МШ-39</td>
                <td style={{ border: fileBorder }}>2,3</td>
                <td style={{ border: fileBorder }}>т</td>
                <td style={{ border: fileBorder }}>9 000,00</td>
                <td style={{ border: fileBorder }}>74 700,00</td>
            </tr>
            <tr>
                <td style={{ border: fileBorder, textAlign: 'center' }}>2</td>
                <td style={{ border: fileBorder }}>Вогнетривкі вироби ША-1-1 № 5</td>
                <td style={{ border: fileBorder }}>5,7</td>
                <td style={{ border: fileBorder }}>кг</td>
                <td style={{ border: fileBorder }}>8 600,00</td>
                <td style={{ border: fileBorder }}>54 600,00</td>
            </tr>
            <tr>
                <td style={{ border: fileBorder, textAlign: 'center' }}>3</td>
                <td style={{ border: fileBorder }}>Фехраль X23Ю5T</td>
                <td style={{ border: fileBorder }}>250</td>
                <td style={{ border: fileBorder }}>кг</td>
                <td style={{ border: fileBorder }}>370,00</td>
                <td style={{ border: fileBorder }}>186 646,00</td>
            </tr>
            <tr>
                <td style={{ border: fileBorder, textAlign: 'center' }}>4</td>
                <td style={{ border: fileBorder }}>Вогнетривка ковдра (мат) LYTX-512, 50х610х3660 мм</td>
                <td style={{ border: fileBorder }}>10</td>
                <td style={{ border: fileBorder }}>шт</td>
                <td style={{ border: fileBorder }}>2 500,00</td>
                <td style={{ border: fileBorder }}>43 534,00</td>
            </tr>
        </tbody>
    </table>
)
const mockProductsResult = (
    <table
        id='mockProductsResult'
        style={{width: "calc(100% - 76px)", borderCollapse: "collapse", fontFamily: fileFontArial, fontSize: '12px',  }}>
        <tbody>
            <tr>
                <td style={{width: '65%',}}></td>
                <td style={{ width: '15%', textAlign: 'start' }}>Разом:</td>
                <td style={{ width: '20%', textAlign: 'end' }}>543 555,00 грн</td>
            </tr>
            <tr>
                <td style={{ width: '65%' }}></td>
                <td style={{ width: '15%', textAlign: 'start' }}>Cума ПДВ:</td>
                <td style={{ width: '20%', textAlign: 'end' }}>60 242,00 грн</td>
            </tr>
            <tr>
                <td style={{ width: '65%' }}></td>
                <td style={{ width: '15%', textAlign: 'start' }}>Усього з ПДВ:</td>
                <td style={{ width: '20%', textAlign: 'end' }}>632 343,00 грн</td>
            </tr>
        </tbody>
    </table>
)

const mockInvoice = (
    <table
        id='mockInvoice'
        style={{width: "calc(100% - 76px)", borderCollapse: "collapse", fontFamily: fileFontArial, fontSize: '15pt',  }}>
        <tbody>
            <tr>
                <td style={{ borderBottom: '2px solid black', width: '100%', textAlign: 'start', fontWeight: 'bold' }}>
                    Рахунок-фактура № 75 від 30 січня 2024 р.
                </td>
            </tr>
        </tbody>
    </table>
)

const sellerData = (
    <table
        style={{
            width: "calc(100% - 76px)", borderCollapse: "collapse",
            fontFamily: fileFontCalibri, lineHeight: '1.3',
        }}>
        <tbody>
            <tr>
                <td style={{border: borderNone, width: '10%'}}></td>
                <td style={{fontFamily: fileFontCalibri, border: borderNone, fontSize: '11pt', width: '15%', textAlign: 'start', textDecoration: 'underline' }}>Постачальник:</td>
                <td style={{fontWeight: '800', fontFamily: fileFontCalibri, border: borderNone, fontSize: '11pt', width: '75%'}}>ФОП "КОНОНІХІН МИХАЙЛО ЄВГЕНОВИЧ"</td>
            </tr>
            <tr>
                <td style={{border: borderNone, width: '10%'}}></td>
                <td style={{border: borderNone, width: '15%', textAlign: 'start'}}></td>
                <td style={{border: borderNone, fontSize: '11pt', width: '75%',}}>
                    П/р UA643133990000026009020205050 , ЗАПОРІЗЬКЕ РУ АТ КБ<br/>
                    "ПриватБанк" 69076, Запоріжжя, вул. Новгородська , буд. № 12, код<br/>
                    за ЄДРПОУ 3575401952, ІПН 357540195270 – для реєстрації ПДВ.<br/>
                </td>
            </tr>
            <tr>
                <td style={{border: borderNone, width: '10%'}}></td>
                <td style={{border: borderNone, fontSize: '11pt', width: '15%', textAlign: 'start', textDecoration: 'underline' }}>Покупець:</td>
                <td style={{border: borderNone, fontSize: '11pt', width: '75%', fontWeight: 'bold'}}>
                    <span id='buyerName'>ПРАТ "ЗАПОРІЖАБРАЗИВ"</span>
                </td>
            </tr>
            <tr>
                <td style={{border: borderNone, width: '10%'}}></td>
                <td style={{border: borderNone, fontSize: '11pt', width: '15%', textAlign: 'start' }}>Договір:</td>
                <td style={{border: borderNone, fontSize: '11pt', width: '75%',}}>Основний договір</td>
            </tr>
        </tbody>
    </table>
)

const footerAlert = () => {


    return (
        <table style={{width: "calc(100% - 76px)", borderCollapse: "collapse", fontFamily: fileFontComic, fontSize: '10pt', textAlign: 'center'}}>
            <tbody>
                <tr>
                    <td>
                        <span
                            style={{ color: 'red', textShadow: textShadow }}>
                            ЗВЕРНІТЬ УВАГУ !  ЗВЕРНІТЬ УВАГУ !</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span style={{ color: 'red', textShadow: textShadow }}>&#10003;</span>
                        <span style={{ marginLeft: '12px', fontWeight: 'bold' }}>Шукаєш матеріали для виробництва, ремонту печей та котлів?</span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
const footerTable = () => {


    return (
        <table style={{width: "calc(100% - 76px)", borderCollapse: "collapse", fontFamily: fileFontComic, fontSize: '8.5pt' }}>
            <tbody>
                <tr style={{ fontWeight: 'bold', fontSize: '8pt' }}>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder, backgroundColor: 'rgb(185, 185, 185)',  }}>Вогнетривкі матеріали до 1600 °С</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder, backgroundColor: 'rgb(185, 185, 185)',  }}>Теплоізоляційна продукція до 1600 °С</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Цегла: ША,ШЛ, МКВ, П ,ХП, ДН</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Мати з керамічного волокна LYTX-112,212,312,512</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Суміш: МШ,ПГОСА,ПШКА, ЗША, МКТТ, КПДО</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Модульні блоки з керамоволокна 300x300x300</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Клеї : ТИ-1К-А(X), CIP, ВТК-800, КТС- 1100</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Вата МКРР-130, Фетр МКРФ-100, Повсть МКРВ-200</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Цемент: GORKAL-(40,50,70), ГЦ-40 , БС-(А-35,А-45)</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Плити LYTX-164(B),264)B),334(B),434(B),534(B)</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Керамічні трубки МКР</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Плити ШПГТ-450 ,МКРП-340 , МКРПГ-400</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Ніхром Х20Н80, Фехраль Х23Ю5Т</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Папір із керамічного волокна LYTX</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Азбест хризотиловий А-6К-30</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Вогнетривкий шнур LYTX, ШАОН</td>
                </tr>
                <tr>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Карбідкремнієві нагрівачі</td>
                    <td style={{ width: '50%', padding: '0.2em 0.5em', border: fileBorder }}>Картон азбестовий (КАОН-1)</td>
                </tr>

            </tbody>
        </table>
    )
}

const stampTable = (params: { stamp: string | undefined }) => {

    return (
        <table
            style={{width: "calc(100% - 76px)", borderCollapse: "collapse", fontSize: '11.5pt', fontFamily: fileFontArial}}>
            <tbody>
                <tr>
                    <td style={{border: borderNone, width: '80%', paddingLeft: '8px'}}>
                        <span style={{ fontFamily: fileFontArial }}>Усього на суму:</span><br/>
                        <span style={{fontWeight: 'bold'}}>
                                <span id='totalSum'>Шістсот тридцять дві тисячі триста сорок три гривні 00 копійок</span>
                            </span><br/>
                        <span style={{fontWeight: 'bold'}}>У т.ч ПДВ: <span id='tax'>14 940,00</span> грн</span>
                    </td>
                    <td rowSpan={5} style={{border: borderNone, width: '20%', textAlign: 'end', paddingRight: '8px'}}>
                        {
                            params.stamp ?
                                <img style={{width: '160px', height: '160px'}}
                                     src={REACT_APP_API_URL + 'media/' + params.stamp} alt="="/>
                                :
                                null
                        }
                    </td>
                </tr>
                <tr>
                    <td style={{border: borderNone, width: '80%', height: '1em'}}></td>
                </tr>
                <tr style={{ fontSize: '10.5pt' }}>
                    <td style={{ border: borderNone, width: '80%', fontFamily: fileFontComic, fontWeight: 'bold' }}>
                        Відповідальний за рахунок:<br/>
                    </td>
                </tr>
                <tr style={{ fontSize: '10.5pt' }}>
                    <td style={{ border: borderNone, width: '80%', height: '1em', fontFamily: fileFontComic, fontWeight: 'bold' }}>
                        <span id='manager'>
                            Олексій Євгенійович, +38 (096) 369 94 30<br/>
                            Андрій Віталійович, +38 (067) 378 14 12
                        </span>
                    </td>
                </tr>
                <tr>
                    <td style={{ border: borderNone, width: '80%', height: '1em', fontFamily: fileFontComic, fontWeight: 'bold' }}>

                    </td>
                </tr>
            </tbody>
        </table>
    )
}


const PdfEditor = () => {
    const { user, ws } = useContext(Context);

    const [isPdfView, setIsPdfView] = useState<boolean>(false);
    const [isManagerEdit, setIsManagerEdit] = useState<boolean>(false);

    const [pdfData, setPdfData] = useState<PdfDataType | null>(null);
    const [managers, setManagers] = useState<Array<{ name: string, phone: string, }> | null>(null);
    const [site, setSite] = useState<string | null>(null);


    useEffect(() => {
        const message = ws.getMsg;
        if (message === WsTriggers.ADMIN) {
            requestPdfData();
            ws.setMessage(null)
        }
    }, [ws.getMsg]);

    const requestPdfData = () => {
        pdfGetDefaultAPI()
            .then((response) => {
                if (response.data) {
                    setPdfData(response.data);
                    setManagers(response.data.pdfSettings.manager);
                    setSite(response.data.pdfSettings.site);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        requestPdfData();
    }, []);




    const pdfViewContainer = (
        <div className="admin__pdf-body-container">
            <div className="admin__pdf-container">
                <div id='parrnetBlock' className="pdf__file-wrapper" style={{
                    width: '100%',
                    height: '100%',
                    fontSize: fileFontSize,
                    color: fileColor,
                    padding: `${fileVerticalPadding} ${fileHorizontalPadding}`
                }}>

                    <div id='mainBlock'>
                        {warningHeader}
                        {indentHigh}
                        {aboutUs({logo: pdfData?.logo})}
                        {indentLow}
                        {siteRef}
                        {indentHigh}
                        {mockInvoice}
                        {indentHigh}
                        {indentHigh}
                        {sellerData}
                        {indentHigh}
                        {mockProductsTable}
                        {mockProductsResult}
                        {indentHigh}
                        {stampTable({stamp: pdfData?.stamp})}
                        {indentHigh}
                    </div>

                    <div id='advBlock'>
                        {footerAlert()}
                        {indentMid}
                        {footerTable()}
                    </div>

                
                


                </div>
            </div>
            <button
                style={{height: '2em', marginLeft: '1em', backgroundColor: 'green',}}
                onClick={() => {
                    let htmlData = `${$('.admin__pdf-container').html()}`;
                    let tempElement = $('<div></div>').html(htmlData);                    

                    tempElement.find('#siteTr').html('{%siteTr%}');
                    tempElement.find('#mockProductsTable').html('{%products%}');
                    tempElement.find('#mockProductsResult').html('');
                    tempElement.find('#mockInvoice').html('{%invoice%}');
                    tempElement.find('#buyerName').html('{%buyerName%}');
                    tempElement.find('#totalSum').html('{%totalSum%}');
                    tempElement.find('#tax').html('{%tax%}');
                    tempElement.find('#manager').html('{%manager%}');

                    htmlData = tempElement.html();
                    console.log(htmlData);
                    
                    pdfUpdateHTMLAPI({htmlData: htmlData})
                        .then((response) => {
                            console.log(response)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }}>
                SAVE
            </button>
        </div>
    )

    const managerView = () => {

        return (
            <form id='pdfManager' onSubmit={(e) => {
                e.preventDefault();
                const t: any = e.target;
                const newMangersArr = [];
                for (let i = 0; i < 3; i ++) {
                    const name = t[`managersName${i}`] ? t[`managersName${i}`].value: '';
                    const phone = t[`managersPhone${i}`] ? t[`managersPhone${i}`].value: '';

                    if (name.length > 0 || phone.length > 0) {
                        newMangersArr.push({ name: name, phone: phone })
                    }
                }
                
                pdfManagerUpdateAPI({ manager: newMangersArr, site: t.site.value })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsManagerEdit(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }}>
                <table className='admin__pdf-manager-settings-table'>
                    {
                        managers ?
                            isManagerEdit ?
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'center', textDecoration: 'underline', fontWeight: '700', backgroundColor: '#3b3b3b'}}>«Сайт»</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                name='site'
                                                defaultValue={ site ? site : '' }/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'center', textDecoration: 'underline', fontWeight: '700', backgroundColor: '#3b3b3b'}}>«Відповідальний за рахунок»</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersName0'
                                                   defaultValue={managers[0] ? managers[0].name : ''}/>
                                        </td>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersPhone0'
                                                   defaultValue={managers[0] ? managers[0].phone : ''}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersName1'
                                                   defaultValue={managers[1] ? managers[1].name : ''}/>
                                        </td>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersPhone1'
                                                   defaultValue={managers[1] ? managers[1].phone : ''}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersName2'
                                                   defaultValue={managers[2] ? managers[2].name : ''}/>
                                        </td>
                                        <td style={{padding: '0', backgroundColor: '#505050FF'}}>
                                            <input className='admin__pdfSettings-input' type="text"
                                                   style={{ backgroundColor: '#505050FF', fontSize: '90%' }}
                                                   name='managersPhone2'
                                                   defaultValue={managers[2] ? managers[2].phone : ''}/>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'center', textDecoration: 'underline', fontWeight: '700', backgroundColor: '#3b3b3b'}}>«Сайт»</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{padding: '0 0.5em'}}>
                                            {
                                                site ? site : <span style={{ opacity: '0' }}>-</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'center', textDecoration: 'underline', fontWeight: '700', backgroundColor: '#3b3b3b'}}>«Відповідальний за рахунок»</td>
                                    </tr>
                                    {
                                        managers[0] ?
                                            <tr>
                                                <td style={{padding: '0 0.5em'}}>{managers[0].name}</td>
                                                <td style={{padding: '0 0.5em'}}>{managers[0].phone}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                            </tr>
                                    }
                                    {
                                        managers[1] ?
                                            <tr>
                                                <td style={{padding: '0 0.5em'}}>{managers[1].name}</td>
                                                <td style={{padding: '0 0.5em'}}>{managers[1].phone}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                            </tr>
                                    }
                                    {
                                        managers[2] ?
                                            <tr>
                                                <td style={{padding: '0 0.5em'}}>{managers[2].name}</td>
                                                <td style={{padding: '0 0.5em'}}>{managers[2].phone}</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                                <td style={{padding: '0 0.5em', opacity: '0'}}>-</td>
                                            </tr>
                                    }

                                </tbody>
                            :
                            null
                    }
                </table>
            </form>
        )
    }


    return (
        <div className='admin__body page_content_body'>
            <div className="admin__users-container contentWindow admin__pdf-content-container">
                <div className="admin__data-header contentWindow__header"><span
                    className='contentWindow__name'>Настройка pdf</span>
                </div>
                <nav className="admin__data-nav contentWindow__nav">
                    {
                        isManagerEdit ?
                            <div>
                                <button
                                    className="admin__nav-button admin__nav-button-save nav__button"
                                    type='submit'
                                    form='pdfManager'
                                style={{ marginRight: '0.25em' }}
                                >Сохранить
                                </button>
                                <button className="admin__nav-button admin__nav-button-cancel nav__button"
                                        onClick={() => {

                                            setIsManagerEdit(false);
                                        }}
                                        style={{ marginLeft: '0.25em' }}
                                >Отмена
                                </button>
                            </div>
                            :
                            <button className="admin__nav-button admin__edit-button nav__button"
                                    onClick={() => setIsManagerEdit(true)}
                                >Редактировать
                            </button>
                    }
                </nav>
                <div className="admin__pdf-body-settings">
                    {managerView()}
                </div>
                {
                    isPdfView ? pdfViewContainer : null
                }
            </div>
        </div>

    );
};

export default PdfEditor;
