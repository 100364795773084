import React, {useContext, useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import './styles/App.css';
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {checkApi, loginApi} from "./http/userApi";
import {getCookie, setCookie} from "./utils/cookies";
import {WS_URL} from "./config";


const jwt_decode = require("jwt-decode");


type PermsType = {
    id: number,
    name: string,
    verboseName: string,
    createdAt: string,
    updatedAt: string
}



const App = observer(() => {
    const { user } = useContext(Context);


    useEffect(() => {
        if (localStorage.getItem('token')) {
            checkApi()
                .then((data) => {
                if (data.response) {
                    if (data.response.status === 401 || data.response.status === 403) {
                        localStorage.removeItem('token');
                        user.setIsAuth(false);
                        user.setUser({});
                        user.setPerms(new Set());
                        checkCookie();
                    }
                } else if (data.status === 200) {
                    localStorage.setItem('token', data.data.token);
                    const decodedToken = jwt_decode.jwtDecode(data.data.token);
                    user.setIsAuth(true);
                    user.setUser(decodedToken);
                    const permissions: Set<string> = new Set();
                    decodedToken.permissions.forEach((p: PermsType) => {
                        permissions.add(p.name)
                    });
                    user.setPerms(permissions);
                }
            })
                .catch((err) => {
                    console.log(err)
                })
        } else if (!user.getIsAuth) {
            checkCookie();
        }
    }, [user.getIsAuth]);


    function checkCookie(){
        const cookies = getCookie('username', 'password');
        if (cookies) {
            const {username, password} = cookies;
            if (username && password) {
                loginApi({ username: username, password: password })
                    .then((data) => {
                        if (data.status === 200) {
                            user.setIsAuth(true);
                            user.setUser({});
                            localStorage.setItem('token', data.data.token);
                        } else if (data.response.status === 404){
                            setCookie('username', '', -365);
                            setCookie('password', '', -365);
                        }
                    }).catch((e) => {

                })
            }
        }
    }


    return (
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
    );
});


export default App;
