import React from 'react';
import { getRoutes } from "../routes";


const AppRouter = () => {
    const routes = getRoutes()

    return (
        routes
    );
};

export default AppRouter;