import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";




export async function calculationGetByIdAPI(
    calcId: string | number,
){
    try {
        return await $authHost.get(API_ENDPOINTS.CALCULATION + '/' + `${calcId}` );

    } catch (error: any) {
        return error;
    }
}

export async function calculationGetAPI(
    companyId: string | 'all',
    queryParam: 'calcAll' | 'calcPaid' | 'calcNotPaid' | 'calcPending',

){
    try {
        return await $authHost.get(API_ENDPOINTS.CALCULATION + '?type=' + queryParam + '&company=' + companyId );

    } catch (error: any) {
        return error;
    }
}

export async function calculationPostAPI(data: any, companyId: string | undefined, userId: string){
    try {
        if (companyId) {
            return await $authHost.post(API_ENDPOINTS.CALCULATION + '/' + companyId + '?userId=' + userId, data);
        }

    } catch (error: any) {
        return error;
    }
}

export async function calculationUpdateStatusAPI(
    calcId: string | number | undefined,
    status: 'paid' | 'notPaid',
    userId: string | number
){
    try {
        if (calcId) {
            return await $authHost.put(API_ENDPOINTS.CALCULATION + `?calcId=${calcId}&status=${status}&userId=${userId}`);
        }

    } catch (error: any) {
        return error;
    }
}

export async function calculationUpdateTbDataAPI(calcId: string | number | undefined, data: any){
    try {
        if (calcId) {
            return await $authHost.put(API_ENDPOINTS.CALCULATION + `/${calcId}`, data);
        }

    } catch (error: any) {
        return error;
    }
}

export async function calculationDeleteAPI(companyId: string | number | undefined){
    try {
        if (companyId) {
            return await $authHost.delete(API_ENDPOINTS.CALCULATION + '/' + companyId);
        }

    } catch (error: any) {
        return error;
    }
}

