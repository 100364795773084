import React, {useContext, useEffect, useState} from 'react';
import '../styles/CalculationsContainer.css';
import $ from 'jquery';
import {
    calculationDeleteAPI,
    calculationGetAPI,
    calculationGetByIdAPI,
    calculationUpdateStatusAPI, calculationUpdateTbDataAPI
} from "../http/calculationAPI";
import {Context} from "../index";
import {WsTriggers} from "../WebsocketTriggers";
import {formatDateString} from "../utils/utilsFunctions";
import CalculationTable, {fillUpTable, gatherTableData, setTableState} from "./CalculationTable";
import {pdfPutAPI} from "../http/pdfAPI";
import {REACT_APP_API_URL} from "../config";
import { getCookie, setCookie } from '../utils/cookies';

const unwrapIco = require('../assets/icons/selectedComp/unwrapIco.png')


type OptionsType = 'calcAll' | 'calcPaid' | 'calcNotPaid' | 'calcPending';
export type CalculationDataType = {
    id: number,
    status: boolean | null,
    invoice: string | null,
    calculation: {
        tableData: {
            [key: number]: {
                a: string,
                b: string,
                c: string,
                d: string,
                e: string,
                f: string,
                g: string,
                h: string,
                i: string,
                j: string,
                k: string,
                l: string,
            }
        },
        tableResult: {
            expensesTax: string,
            incomeTax: string,
            profit: string,
        }
    },
    createdAt: string,
    updatedAt: string,
    closedAt: string | null,
    companyId: number,
    pdfFile: string | null,
    company: {
        id: number,
        name: string
    },
    createdBy: { id: number, username: string },
    closedBy: { id: number, username: string }
}

const statusToJSX: { [key: string]: JSX.Element } = {
    'null': <span className="" style={{ color: '#FAC768FF' }}>В ожидании</span>,
    'false': <span className="" style={{ color: '#FF5858FF' }}>Не оплачен</span>,
    'true': <span className="" style={{ color: '#85FF85FF' }}>Оплачен</span>,
}



const CalculationsContainer = (props: {
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
    setHeaderName: React.Dispatch<React.SetStateAction<string>>
    setBodyDivElement: React.Dispatch<React.SetStateAction<JSX.Element>>
}) => {
    const {user, ws} = useContext(Context);

    useEffect(() => {
        const message: string | null = ws.getMsg;
        if (message !== null) {
            const compId = `${window.location.pathname}`.split('/').pop();
            if (message === WsTriggers.CALC + `+${compId}`) {
                requestCalculation();

                ws.setMessage(null);
            }

        }
    }, [ws.getMsg]);


    const [selectedOption, setSelectedOption] = useState<OptionsType>('calcPending');
    const [companyId, setCompanyId] = useState(window.location.pathname.split('/').pop());

    const [calcData, setCalcData] = useState<Array<CalculationDataType>>([]);
    const [calcDataById, setCalcDataById] = useState<CalculationDataType | null>(null);

    const [perms, setPerms] = useState<Set<string>>(new Set());

    const [isTableEditable, setIsTableEditable] = useState<boolean>(false);

    useEffect(() => {
        setPerms(user.getPermissions.data_);
    }, [user.getPermissions]);

    useEffect(() => {
        $('.selectedComp__calc-button').css({
            'box-shadow': '0 0 0.25em #e9e9e9',
            'color': '#e9e9e9',
        });
        $(`#${selectedOption}`).css({
            'box-shadow': '0 0 0.25em #FAC768FF',
            'color': '#FAC768FF',
        });

        requestCalculation();
    }, [selectedOption]);

    const requestCalculation = () => {
        if (companyId) {
            calculationGetAPI(companyId, selectedOption)
                .then((response) => {
                    if (response.data) {
                        setCalcData(response.data);                        
                    }
                    if (calcDataById && $('#popup').length > 0) {
                        requestCalculationById(calcDataById.id);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const requestCalculationById = (id: string | number) => {
        if (companyId) {
            calculationGetByIdAPI(id)
                .then((response) => {
                    if (response.data) {
                        setCalcDataById(response.data);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        if (calcDataById) {
            props.setIsActive(true)
            props.setHeaderName('Расчёт')
            props.setBodyDivElement(popupCalcTable)
        }
        if ($('.calcPopup__save-btn').css('display') == 'block') {
            getTableToPrevCondition();
        }
    }, [calcDataById]);


    const changeCalcStatus = (status: 'paid' | 'notPaid') => {
        if (calcDataById) {
            calculationUpdateStatusAPI(calcDataById.id, status, user.getUser.id)
                .then((response) => {
                    if (response.status === 200) {
                        requestCalculationById(calcDataById.id);

                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const getTableToPrevCondition = () => {
        if (calcDataById) {
            fillUpTable({ Key: 'Popup', tableData: calcDataById.calculation.tableData })
            setTableState({ Key: 'Popup', isEditable: false });
            $('.calcPopup__save-btn').css({ display: 'none' });
            $('.calcPopup__cancel-btn').css({ display: 'none' });
        }
    };

    const editTableDataHandler = () => {
        $('.calcPopup__save-btn').css({ display: 'block' });
        $('.calcPopup__cancel-btn').css({ display: 'block' });

        $(document).off('click', '.calcPopup__save-btn');
        $(document).off('click', '.calcPopup__cancel-btn');
        $(document).on('click', '.calcPopup__save-btn', function () {
            const updatedTbData = gatherTableData({ Key: 'Popup' });
            if (calcDataById) {
                calculationUpdateTbDataAPI(calcDataById.id, updatedTbData)
                    .then((response) => {
                        if (response.status === 200) {
                            requestCalculationById(calcDataById.id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        })
        $(document).on('click', '.calcPopup__cancel-btn', function () {
            getTableToPrevCondition();
        })



    }

    const createPdfBtn = (
            <button className='calcPopup__toolsBtn calcPopup__toolsBtn-pdf'
                    onClick={() => {
                        if (calcDataById) {
                            $('.calcPopup__toolsBtn-pdf').css({ 'display': 'none' });
                            $('.pdfUpdateLoader').css({ 'display': 'block' });
                            $('.calcPopup__toolsBtn-delete').css({ 'display': 'none' });
                            $('.calcPopup__toolsBtn-delete-unusable').css({ 'display': 'block' });

                            pdfPutAPI(calcDataById.id)
                                .then((response) => {
                                    console.log(response)
                                    $('.pdfUpdateLoader').css({ 'display': 'none' });
                                    $('.calcPopup__toolsBtn-pdf').css({ 'display': 'block' });
                                    $('.calcPopup__toolsBtn-delete').css({ 'display': 'block' });
                                    $('.calcPopup__toolsBtn-delete-unusable').css({ 'display': 'none' });
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    }}
            >Сформировать pdf</button>
    )
    const reCreatePdfBtn = (
        <button className='calcPopup__toolsBtn calcPopup__toolsBtn-pdf-recreate'
                onClick={() => {
                    if (calcDataById) {
                        $('.calcPopup__toolsBtn-pdf-recreate').css({ 'display': 'none' });
                        $('.pdfUpdateLoader').css({ 'display': 'block' });
                        $('.calcPopup__toolsBtn-delete').css({ 'display': 'none' });
                        $('.calcPopup__toolsBtn-delete-unusable').css({ 'display': 'block' });


                        pdfPutAPI(calcDataById.id)
                            .then((response) => {
                                console.log(response)
                                $('.pdfUpdateLoader').css({ 'display': 'none' });
                                $('.calcPopup__toolsBtn-pdf-recreate').css({ 'display': 'block' });
                                $('.calcPopup__toolsBtn-delete').css({ 'display': 'block' });
                                $('.calcPopup__toolsBtn-delete-unusable').css({ 'display': 'none' });

                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                }}
        >Пересоздать pdf</button>
    )

    const popupCalcTable = (
        <div className='selectedComp__calc-popup-body-wrapper'>
            {
                perms.has('calculation.update') || perms.has('calculation.delete') ?
                    calcDataById ?
                        <div className='calcPopup-toolsPanel hidden'>
                            {
                                perms.has('calculation.update') ?
                                    <div>
                                        {
                                            calcDataById.status === null ?
                                                <button className='calcPopup__toolsBtn calcPopup__toolsBtn-paid'
                                                        onClick={() => {
                                                            changeCalcStatus('paid')
                                                        }}
                                                >Оплачено</button>
                                                :
                                                <button className='calcPopup__toolsBtn-unusable'>Оплачено</button>
                                        }
                                        {
                                            calcDataById.status === null ?
                                                <button className='calcPopup__toolsBtn calcPopup__toolsBtn-notPaid'
                                                        onClick={() => {
                                                            changeCalcStatus('notPaid')
                                                        }}
                                                >Не оплачено</button>
                                                :
                                                <button className='calcPopup__toolsBtn-unusable'>Не оплачено</button>
                                        }
                                        {
                                            perms.has('calculation.update') ?
                                                calcDataById.status === null ?
                                                    calcDataById.pdfFile ?
                                                        reCreatePdfBtn
                                                        :
                                                        createPdfBtn
                                                    :
                                                    calcDataById.pdfFile ?
                                                        <button className='calcPopup__toolsBtn-unusable'>Сформировать
                                                            pdf</button>
                                                        :
                                                        createPdfBtn
                                                :
                                                null
                                        }
                                        <button className='pdfUpdateLoader calcPopup__toolsBtn-unusable'>Создание pdf
                                        </button>
                                        {
                                            calcDataById.status === null ?
                                                <button className='calcPopup__toolsBtn calcPopup__toolsBtn-edit'
                                                        onClick={() => {
                                                            setTableState(
                                                                {Key: 'Popup', isEditable: true,});

                                                            editTableDataHandler();

                                                        }}
                                                >Редактировать</button>
                                                :
                                                <button className='calcPopup__toolsBtn-unusable'>Редактировать</button>
                                        }
                                    </div>
                                    :
                                    null
                            }
                            {
                                perms.has('calculation.delete') ?
                                    <button className='calcPopup__toolsBtn calcPopup__toolsBtn-delete'
                                            onClick={() => {
                                                if (calcDataById) {
                                                    calculationDeleteAPI(calcDataById.id)
                                                        .then((response) => {
                                                            console.log(response)
                                                            if (response.status === 200) {
                                                                props.setIsActive(false);
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            console.log(err)
                                                        })
                                                }
                                            }}
                                    >Удалить</button>
                                    :
                                    null
                            }
                            <button className='calcPopup__toolsBtn-unusable calcPopup__toolsBtn-delete-unusable'>Удалить</button>
                        </div>
                        : null
                    :
                    null
            }
            {
                perms.has('calculation.update') || perms.has('calculation.delete') ?
                    <div className='calcPopup-toolsPanel-reveal hidden'
                         onClick={() => {
                             $('.calcPopup-toolsPanel-reveal').toggleClass('hidden');
                             $('.calcPopup-toolsPanel').toggleClass('hidden');
                             $('.calcPopup-toolsPanel-reveal-span').toggleClass('hidden');

                         }}
                    ><span className='calcPopup-toolsPanel-reveal-span hidden'>&#9001;</span>
                    </div>
                : null

            }
            {
                calcDataById ?
                    <table className='calcPopup__table'>
                        <tbody className='calcPopup__tbody'>
                        <tr className='calcPopup__tr'>
                            <td className='calcPopup__td calcPopup__td-static'>Создал:</td>
                            <td className='calcPopup__td calcPopup__td-dynamic'>{calcDataById.createdBy ? calcDataById.createdBy.username : '-'}</td>
                            <td className='calcPopup__td calcPopup__td-static'>Дата:</td>
                            <td className='calcPopup__td calcPopup__td-dynamic'>{formatDateString(calcDataById.createdAt)}</td>
                            <td className='calcPopup__td calcPopup__td-static'>Счёт-фактура:</td>
                            <td className='calcPopup__td calcPopup__td-dynamic'>{calcDataById.invoice ? calcDataById.invoice : '-'}</td>
                            <td className='calcPopup__td calcPopup__td-static'>PDF:</td>
                            <td className='calcPopup__td calcPopup__td-dynamic'>{calcDataById.pdfFile ?
                                <a href={`${REACT_APP_API_URL}media/${calcDataById.pdfFile}`}
                                        target='_blank'
                                        onClick={() => {
                                            const cookieJwt = localStorage.getItem('token');
                                            if (cookieJwt) {
                                                setCookie('cookieJwt', cookieJwt, 7);
                                            }
                                        }}      
                                >{calcDataById.pdfFile}</a> : '-'}</td>
                        </tr>
                        <tr className='calcPopup__tr'>
                            <td className='calcPopup__td calcPopup__td-static'>Закрыл:</td>
                            <td className='calcPopup__td calcPopup__td-dynamic'>{calcDataById.closedBy ? calcDataById.closedBy.username : '-'}</td>
                                <td className='calcPopup__td calcPopup__td-static'>Дата:</td>
                                <td className='calcPopup__td calcPopup__td-dynamic'>{calcDataById.closedAt !== null ? formatDateString(calcDataById.closedAt) : '-'}</td>
                                <td className='calcPopup__td calcPopup__td-static'>Статус:</td>
                                <td className='calcPopup__td calcPopup__td-dynamic'>{statusToJSX[`${calcDataById.status}`]}</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null
            }
            <div className='calcPopup__calculation-container'>
                <CalculationTable
                    isEditable={isTableEditable}
                    Key={'Popup'}
                    tableData={ calcDataById ? calcDataById.calculation.tableData : undefined }
                />
            </div>
            <div className='calcPopup__result-container'>
                <div className='calcPopup__result-btn-container'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <button className='calcPopup__save-btn calcPopup__toolsBtn'>Сохранить</button>
                        <button className='calcPopup__cancel-btn calcPopup__toolsBtn'>Отмена</button>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <span className='cmpny-Popup__calc-result-expenses-tax calc-result-expenses-tax'>Сумма расхода с НДС: 0</span>
                    <span
                        className='cmpny-Popup__calc-result-income-tax calc-result-income-tax'>Сумма дохода с НДС: 0</span>
                    <span className='cmpny-Popup__calc-result-profit calc-result-profit'>Прибыль: 0</span>
                </div>
            </div>
        </div>
    )


    return (
        <div className="selectedComp__calcs-container contentWindow">
            <div className="selectedComp__data-header contentWindow__header">
                <span className="contentWindow__name">Расчёты</span>
            </div>
            <nav className="selectedComp__data-nav contentWindow__nav">
                <button id='calcAll' className="selectedComp__calc-button nav__button" onClick={() => {
                    setSelectedOption('calcAll')
                }}>Все
                </button>
                <button id='calcPaid' className="selectedComp__calc-button nav__button" onClick={() => {
                    setSelectedOption('calcPaid')
                }}>Оплачено
                </button>
                <button id='calcNotPaid' className="selectedComp__calc-button nav__button" onClick={() => {
                    setSelectedOption('calcNotPaid')
                }}>Не оплачено
                </button>
                <button id='calcPending' className="selectedComp__calc-button nav__button" onClick={() => {
                    setSelectedOption('calcPending')
                }}>В ожидании
                </button>
            </nav>
            <div className="selectedComp__calc-data-body contentWindow__body">
                {
                    calcData.length === 0 ?
                        <div className="selectedComp__calculation-container-wrapper"
                             style={{display: 'flex', justifyContent: 'center', color: 'white'}}
                        >Ничего нет</div>
                        :
                        calcData.map((cd) =>
                            <div key={`calc${cd.id}`} className="selectedComp__calculation-container-wrapper">
                                <div className="selectedComp__calculation-container">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='selectedComp__calc-td-static'>Создал:</td>
                                            <td className='selectedComp__calc-td-dynamic'>{cd.createdBy ? cd.createdBy.username : '-'}</td>
                                            <td className='selectedComp__calc-td-static'>Дата:</td>
                                            <td className='selectedComp__calc-td-dynamic'>{formatDateString(cd.createdAt)}</td>
                                            <td className='selectedComp__calc-td-static'>Счёт-фактура: </td>
                                            <td className='selectedComp__calc-td-dynamic'>{ cd.invoice ? cd.invoice : '-' }</td>
                                            <td className='selectedComp__calc-td-btn-container'>
                                                <button className='selectedComp__calc-btn-openPopup'
                                                    onClick={() => {
                                                        requestCalculationById(cd.id);
                                                    }}
                                                >
                                                    <img src={unwrapIco} className='selectedComp__calc-btn-openPopup-unwrapIco' alt="-"/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='selectedComp__calc-td-static'>Закрыл:</td>
                                            <td className='selectedComp__calc-td-dynamic'>{cd.closedBy ? cd.closedBy.username : '-'}</td>
                                            <td className='selectedComp__calc-td-static'>Дата:</td>
                                            <td className='selectedComp__calc-td-dynamic'>{cd.closedAt !== null ? formatDateString(cd.closedAt) : '-'}</td>
                                            <td className='selectedComp__calc-td-static'>Статус:</td>
                                            <td className='selectedComp__calc-td-dynamic'>{statusToJSX[`${cd.status}`]}</td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )

                }
            </div>
        </div>
    );
};

export default CalculationsContainer;
