import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";


type LogParams = {
    username: string,
    password: string,
}


export async function usersGetApi(){
    try {
        return await $authHost.get(API_ENDPOINTS.USER);

    } catch (error: any) {
        return error;
    }
}

export async function getPermissionsApi(){
    try {
        return await $authHost.get(API_ENDPOINTS.PERMISSIONS);

    } catch (error: any) {
        return error;
    }
}

export async function registerApi (data: any) {
    try {
        return await $authHost.post(API_ENDPOINTS.USER + URLS.REGISTRATION_URL, data);
    } catch (error: any) {
        return error;
    }
}

export async function loginApi ({ username, password }: LogParams) {
    try {
        const response = await $host.post(API_ENDPOINTS.USER + URLS.LOGIN_URL, { username, password });
        localStorage.setItem('token', response.data.token)
        return response;

    } catch (error: any) {
        return error;
    }
}

export async function checkApi () {
    try {
        const response = await $authHost.get(API_ENDPOINTS.USER + URLS.CHECK);
        localStorage.setItem('token', response.data.token)
        return response;

    } catch (error: any) {
        return error;
    }
}

export async function userPutApi(id: number | string, data: any){
    try {
        return await $authHost.put(API_ENDPOINTS.USER + `/${id}`, data);

    } catch (error: any) {
        return error;
    }
}

export async function userDeleteApi(id: number | string){
    try {
        return await $authHost.delete(API_ENDPOINTS.USER + `/${id}`);

    } catch (error: any) {
        return error;
    }
}

export async function userCurrentGetApi(){
    try {
        return await $authHost.get(API_ENDPOINTS.CURRENT_USER);

    } catch (error: any) {
        return error;
    }
}

export async function userCurrentPutApi(data: any){
    try {
        return await $authHost.put(API_ENDPOINTS.CURRENT_USER, data);

    } catch (error: any) {
        return error;
    }
}

