import React, {useContext, useEffect, useState} from 'react';
import NavPanel from "../components/NavPanel";
import '../styles/Admin.css';
import {getPermissionsApi, registerApi, userDeleteApi, userPutApi, usersGetApi} from "../http/userApi";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import PdfEditor from "../components/PdfEditor";
import {WsTriggers} from "../WebsocketTriggers";


type UserType = {
    id: number,
    username: string,
    password?: string,
    firstName: string | null,
    lastName: string | null,
    middleName: string | null,
    permissions: [] | Array<UserPermsType>,
    phone: string | null,
    status: boolean,
    role: "SUPERUSER" | "USER"
}
type UsersDictType = {[key: number]: UserType};
type UserPermsType = {id: number, name: string, verboseName: string}


const userRoles = { 'SUPERUSER': 'Администратор', 'USER': 'Пользователь' };
const userStatuses = {
    true: <span style={{color: '#85FF85FF'}}>Активен</span>,
    false: <span style={{color: '#FF5858FF'}}>Неактивен</span>
}






const Admin = observer(() => {
    const { user, ws } = useContext(Context);
    
    const [users, setUsers] = useState<Array<UserType> | []>([]);
    const [usersDict, setUsersDict] = useState<UsersDictType | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const [editingUser, setEditingUser] = useState<UserType | null>(null);


    const [allPerms, setAllPerms] = useState<Array<UserPermsType> | []>([]);
    const [selectedPerms, setSelectedPerms] = useState<Array<UserPermsType> | []>([])
    const [availablePerms, setAvailablePerms] = useState<Array<UserPermsType> | []>([])

    const [userDataChanged, setUserDataChanged] = useState<number>(0);

    const [isDeleteUser, setIsDeleteUser] = useState<boolean>(false);
    const [delUserBtn, setDelUserBtn] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
        const message = ws.getMsg;

    }, [ws.getMsg]);

    useEffect(() => {
        if (user.getUser.username) {
            getPermissionsApi()
            .then((response) => {
                if (response.data) {
                    setAllPerms(response.data);
                }
            })
            .catch((er) => {
            console.log(er)})

        usersGetApi()
            .then((response) => {
                let usersData: Array<UserType> = response.data;
                
                if (user.getUser.username !== 'superuser') {
                    usersData = usersData.filter(el => el.id !== 1)
                }
                
                if (usersData) {
                    setUsers(usersData);
                    const newUsersDict: UsersDictType = {};
                    for (let ud of Object.entries(usersData)){
                        newUsersDict[ud[1].id] = ud[1];
                    }
                    
                    setUsersDict(newUsersDict);
                    setSelectedUser(usersData[0]);
                    setSelectedPerms(usersData[0].permissions);
                    setIsDeleteUser(false);

                } else { setUsers([]) }
            })
            .catch((er) => {
                console.log(er) })
        }
        
    }, [userDataChanged, user.getUser.username]);

    useEffect(() => {
        if (selectedUser === null && editingUser !== null) {
            const newSelected: Array<UserPermsType> = editingUser.permissions;
            if (newSelected.length === 0) {
                setAvailablePerms(allPerms);
                setSelectedPerms([]);
            } else {
                const selectedSet = new Set(newSelected.map((el) => el.name));
                const newAvailable = allPerms.filter((el) => !selectedSet.has(el.name));
                setSelectedPerms(newSelected);
                setAvailablePerms(newAvailable);
            }

        } else if (selectedUser === null) {
            setAvailablePerms(allPerms);
            setSelectedPerms([]);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (isDeleteUser){
            setDelUserBtn(
                <div>
                    <button className="admin__nav-button admin__nav-button-delete nav__button"
                            onClick={() => {
                                if (selectedUser) {
                                    userDeleteApi(selectedUser.id)
                                        .then((response) => {
                                            setUserDataChanged(userDataChanged + 1);
                                        })
                                        .catch((er) => {console.log(er)})
                                }
                            }}
                    >Удалить</button>
                    <button className="admin__nav-button admin__nav-button-cancel nav__button"
                            onClick={() => setIsDeleteUser(false)}
                    >Отмена</button>
                </div>
            )
        } else {
            if (selectedUser && selectedUser.id !== user.getUser.id) {
                setDelUserBtn(
                    <button className="admin__nav-button admin__delete-button nav__button"
                            onClick={() => setIsDeleteUser(true)}
                    >Удалить</button>
                )
            } else { setDelUserBtn(<></>) }
        }
    }, [isDeleteUser, selectedUser]);

    const selectedPermsBlockStatic = (
        <div
            className="admin__user-permissions-window admin__permissions-selected contentWindow">
            <div className="admin__user-permissions-window-header">
                <span className="admin__user-permissions-window-header-name">Права</span>
            </div>
            <div className="admin__user-permissions-window-body">
                {
                    selectedPerms.length > 0 ?
                        selectedPerms.map((el: UserPermsType) =>
                            <div className='admin__user-permission'
                                key={`selectedPerms-${el.name}`}>
                                <span>{el.verboseName}</span>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </div>
    )

    const selectedPermsBlock = (
        <div
            className="admin__user-permissions-window admin__permissions-selected contentWindow">
            <div className="admin__user-permissions-window-header">
                <span className="admin__user-permissions-window-header-name">Права</span>
            </div>
            <div className="admin__user-permissions-window-body">
                {
                    selectedPerms.length > 0 ?
                        selectedPerms.map((el: UserPermsType) =>
                            <div className='admin__user-permission admin__user-permission-dynamic'
                                key={`selectedPerms-${el.name}`} onClick={() => {
                                const newAvailable = availablePerms
                                    .filter((item) => item.name !== el.name);
                                const newSelected = selectedPerms
                                    .filter((item) => item.name !== el.name);

                                newAvailable.push(el);

                                setSelectedPerms(newSelected);
                                setAvailablePerms(newAvailable);

                            }}>
                                <span>{el.verboseName}</span>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </div>
    )

    const availablePermsBlock = (
        <div
            className="admin__user-permissions-window admin__permissions-available contentWindow">
            <div className="admin__user-permissions-window-header">
                <span className="admin__user-permissions-window-header-name">Доступные права</span>
            </div>
            <div className="admin__user-permissions-window-body">
                {
                    availablePerms.length > 0 ?
                        availablePerms.map((el: UserPermsType) =>
                            <div className='admin__user-permission admin__user-permission-dynamic'
                                key={`availablePerms-${el.name}`} onClick={() => {
                                const newAvailable = availablePerms
                                    .filter((item) => item.name !== el.name);
                                const newSelected = selectedPerms
                                    .filter((item) => item.name !== el.name);

                                newSelected.push(el);

                                setSelectedPerms(newSelected);
                                setAvailablePerms(newAvailable);

                            }}>
                                <span>{el.verboseName}</span>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </div>
    );


    return (
        <div className='admin page-content-container'>
            <NavPanel/>
            <div className='admin__content-wrapper'>

                <div className='admin__body page_content_body'>
                    <div className="admin__users-container contentWindow">
                        <div className="admin__data-header contentWindow__header"><span
                            className='contentWindow__name'>Пользователи</span>
                        </div>
                        <nav className="admin__data-nav contentWindow__nav">
                            <button className="admin__nav-button admin__create-button nav__button"
                                    onClick={() => {
                                        setSelectedUser(null);
                                        setEditingUser(null);
                                    }}
                            >Создать
                            </button>
                        </nav>
                        <div className="admin__data-body-users-list contentWindow__body">
                            {
                                users.map((el: UserType) =>
                                    <button key={`userButton${el.id}`}
                                            className='admin__user-button'
                                            onClick={() => {
                                                if (usersDict) {
                                                    setSelectedUser(usersDict[el.id]);
                                                    setSelectedPerms(usersDict[el.id].permissions);
                                                    setIsDeleteUser(false);
                                                }
                                            }}
                                    >{el.username}</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="admin__content-container contentWindow">
                        <div className="admin__data-header contentWindow__header">
                            <span className='contentWindow__name' style={{opacity: 0}}>o</span>
                        </div>
                        {
                            selectedUser !== null ?
                                <nav className="admin__data-nav contentWindow__nav">
                                    <button className="admin__nav-button admin__edit-button nav__button"
                                            onClick={() => {
                                                setEditingUser(selectedUser);
                                                setSelectedUser(null);

                                            }}
                                    >Редактировать
                                    </button>
                                    {
                                        delUserBtn
                                    }
                                </nav>
                                :
                                <nav className="admin__data-nav contentWindow__nav"></nav>
                        }
                        {
                            selectedUser !== null ?
                                <div className="admin__data-body-user-info contentWindow__body">
                                    <div className="admin__user-table-container">
                                        <table className='admin__userInfo-table'>
                                            <tbody className='admin__userInfo-tbody'>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Логин</td>
                                                <td className='admin__userInfo-td'>{selectedUser.username}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Пароль</td>
                                                <td className='admin__userInfo-td'>********</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Имя</td>
                                                <td className='admin__userInfo-td'>{selectedUser.firstName}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Фамилия</td>
                                                <td className='admin__userInfo-td'>{selectedUser.lastName}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Отчество</td>
                                                <td className='admin__userInfo-td'>{selectedUser.middleName}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Телефон</td>
                                                <td className='admin__userInfo-td'>{selectedUser.phone}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Роль</td>
                                                <td className='admin__userInfo-td'>{userRoles[selectedUser.role]}</td>
                                            </tr>
                                            <tr className='admin__userInfo-tr'>
                                                <td className='admin__userInfo-td-static'>Статус</td>
                                                <td className='admin__userInfo-td'>{userStatuses[`${selectedUser.status}`]}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="admin__user-permissions-container admin__user-table-container">
                                        {
                                            selectedPermsBlockStatic
                                        }
                                    </div>
                                </div>
                                :
                                editingUser !== null ?
                                    <div className="admin__data-body-user-info contentWindow__body">
                                        <div className="admin__user-table-container"
                                             style={{display: 'flex', justifyContent: 'center'}}
                                        >
                                            <form id='newUser' className='admin__newUser-form'
                                                  onSubmit={(el) => {
                                                      handleUpdateUserForm(el, editingUser, selectedPerms, userDataChanged, setUserDataChanged);
                                                  }}>

                                                <input name='username' type="text" placeholder='Логин*' required={true}
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue={editingUser.username}
                                                />
                                                <input name='password' type="text" placeholder='Пароль*' required={true}
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue='********'
                                                />
                                                <input name='firstName' type="text" placeholder='Имя'
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue={editingUser.firstName !== null ? editingUser.firstName : ''}
                                                />
                                                <input name='lastName' type="text" placeholder='Фамилия'
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue={editingUser.lastName !== null ? editingUser.lastName : ''}
                                                />
                                                <input name='middleName' type="text" placeholder='Отчество'
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue={editingUser.middleName !== null ? editingUser.middleName : ''}
                                                />
                                                <input name='phone' type="text" placeholder='Телефон'
                                                       className='admin__input-field' maxLength={64}
                                                       defaultValue={editingUser.phone !== null ? editingUser.phone : ''}
                                                />
                                                <label className='admin__label-status'>
                                                    <input name='status' type="checkbox"
                                                           defaultChecked={editingUser.status}/>
                                                    Активен
                                                </label>
                                                <label className='admin__label-role-user'>
                                                    <input type="radio" name='role' value='USER'
                                                           defaultChecked={editingUser.role === 'USER' ? true : false}/>
                                                    Пользователь
                                                </label>
                                                <label className='admin__label-role-superuser'>
                                                    <input type="radio" name='role' value='SUPERUSER'
                                                           defaultChecked={editingUser.role === 'SUPERUSER' ? true : false}/>
                                                    Администратор
                                                </label>
                                                <span id='msgField'></span>
                                                <button className='admin__from-btn' formTarget='newUser'
                                                        type='submit'>Сохранить
                                                </button>
                                            </form>
                                        </div>

                                        <div className="admin__user-permissions-container admin__user-table-container">
                                            {
                                                selectedPermsBlock
                                            }
                                            {
                                                availablePermsBlock
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="admin__data-body-user-info contentWindow__body">
                                        <div className="admin__user-table-container"
                                             style={{display: 'flex', justifyContent: 'center'}}
                                        >
                                            <form id='newUser' className='admin__newUser-form'
                                                  onSubmit={(el) => {
                                                      handleNewUserForm(el, selectedPerms, userDataChanged, setUserDataChanged);
                                                  }}>
                                                <input name='username' type="text" placeholder='Логин*' required={true}
                                                       className='admin__input-field' maxLength={64}/>
                                                <input name='password' type="text" placeholder='Пароль*' required={true}
                                                       className='admin__input-field' maxLength={64}/>
                                                <input name='firstName' type="text" placeholder='Имя'
                                                       className='admin__input-field' maxLength={64}/>
                                                <input name='lastName' type="text" placeholder='Фамилия'
                                                       className='admin__input-field' maxLength={64}/>
                                                <input name='middleName' type="text" placeholder='Отчество'
                                                       className='admin__input-field' maxLength={64}/>
                                                <input name='phone' type="text" placeholder='Телефон'
                                                       className='admin__input-field' maxLength={64}/>
                                                <label className='admin__label-status'>
                                                    <input name='status' type="checkbox" defaultChecked={true}/>
                                                    Активен
                                                </label>
                                                <label className='admin__label-role-user'>
                                                    <input type="radio" name='role' value='USER' defaultChecked={true}/>
                                                    Пользователь
                                                </label>
                                                <label className='admin__label-role-superuser'>
                                                    <input type="radio" name='role' value='SUPERUSER'/>
                                                    Администратор
                                                </label>
                                                <span id='msgField'></span>
                                                <button className='admin__from-btn' formTarget='newUser'
                                                        type='submit'>Сохранить
                                                </button>
                                            </form>
                                        </div>

                                        <div className="admin__user-permissions-container admin__user-table-container">
                                            {
                                                selectedPermsBlock
                                            }
                                            {
                                                availablePermsBlock
                                            }
                                        </div>
                                    </div>
                        }

                    </div>
                </div>
                <PdfEditor/>

            </div>

        </div>
    );
})


function handleUpdateUserForm(targetElement: any,
                              editingUser: UserType,
                              selectedPerms: Array<UserPermsType>,
                              userDataChanged: number,
                              setUserDataChanged: React.Dispatch<React.SetStateAction<number>>) {
    targetElement.preventDefault();
    const {username, password, firstName, lastName, middleName, phone, status, role} = targetElement.target;

    if (password.value.length < 8) {
        putResponseMessage('Пароль должен быть больше 8 символов')
        return
    }

    // @ts-ignore
    const userData: UserType = {
        username: username.value,
        firstName: firstName.value.length === 0 ? null : firstName.value,
        lastName: lastName.value.length === 0 ? null : lastName.value,
        middleName: middleName.value.length === 0 ? null : middleName.value,
        permissions: selectedPerms,
        phone: phone.value.length === 0 ? null : phone.value,
        status: status.checked,
        role: role[0].checked ? role[0].value : role[1].value,
    }
    if (password.value !== '********') {
        userData['password'] = password.value;
    }

    userPutApi(editingUser.id, userData)
        .then((response) => {
            if (response.response && response.response.status && response.response.status >= 400) {
                putResponseMessage(`${response.response.data.message}`)
                return
            }

            setUserDataChanged(userDataChanged + 1);

        })
        .catch((er) => {
            console.log(er)
        })

}


function handleNewUserForm(targetElement: any, selectedPerms: Array<UserPermsType>,
                           userDataChanged: number,
                           setUserDataChanged: React.Dispatch<React.SetStateAction<number>>) {
    targetElement.preventDefault();
    const {username, password, firstName, lastName, middleName, phone, status, role} = targetElement.target;

    const userData = {
        username: username.value,
        password: password.value,
        firstName: firstName.value.length === 0 ? null : firstName.value,
        lastName: lastName.value.length === 0 ? null : lastName.value,
        middleName: middleName.value.length === 0 ? null : middleName.value,
        permissions: selectedPerms,
        phone: phone.value.length === 0 ? null : phone.value,
        status: status.checked,
        role: role[0].checked ? role[0].value : role[1].value,
    }

    registerApi(userData)
        .then((response) => {
            if (response.response && response.response.status === 400) {
                putResponseMessage(`${response.response.data.message}`);
            } else {
                setUserDataChanged(userDataChanged + 1);
            }
        })
        .catch((er) => {
            console.log(er)
        })

}


function putResponseMessage(message: string) {
    const msgItem = document.getElementById('msgField');
    if (msgItem) {
        msgItem.style.color = '#FF5858FF';
        msgItem.innerText = message;
    }
}


export default Admin;