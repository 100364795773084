import React, {useContext, useEffect, useState} from 'react';
import '../styles/Main.css'
import NavPanel from '../components/NavPanel'
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import HistoryContainer from "../components/HistoryContainer";
import {WsTriggers} from "../WebsocketTriggers";
import {taskDeleteAPI, taskGetAPI, taskPostAPI, taskPutAPI} from "../http/taskAPI";
import {addMessageIntoHtml, formatDateString} from "../utils/utilsFunctions";
import {companyGetAPI} from "../http/companyAPI";
import {URLS} from "../utils/urls";
import { calculationGetAPI, calculationUpdateStatusAPI } from "../http/calculationAPI";
import $ from 'jquery';

const paidIco = require('../assets/icons/main/paidIco.png');
const notPaidIco = require('../assets/icons/main/notPaidIco.png');

const saveIco = require('../assets/icons/selectedComp/saveIco.png');
const saveUnactiveIco = require('../assets/icons/selectedComp/saveUnactiveIco.png');
const editIco = require('../assets/icons/selectedComp/editIco.png');
const editUnactiveIco = require('../assets/icons/selectedComp/editUnactiveIco.png');
const delIco = require('../assets/icons/selectedComp/deleteIco.png');
const backIco = require('../assets/icons/selectedComp/backIco.png');


type TaskOptionsType = 'taskAll' | 'taskMine' | 'taskNew';

type UsersType = { [key: number]: string };
type CompaniesType = Array<[ number, string ]>;
type TaskDataObjectType = {
    id: number,
    context: string,
    status: boolean | null,
    createdAt: string,
    updatedAt: string,
    companyTaskId: number | null,
    doingTaskById: number | null,
    createdTaskById: number | null,
    company: { id: number, name: string } | null,
    doingBy: { id: number, username: string } | null,
    createdBy: { id: number, username: string } | null,
}

type CalculationDataType = {
    id: number,
    status: boolean | null,
    invoice: string | null,
    calculation: {
        tableData: {
            [key: number]: {
                a: string,
                b: string,
                c: string,
                d: string,
                e: string,
                f: string,
                g: string,
                h: string,
                i: string,
                j: string,
                k: string,
                l: string,
            }
        },
        tableResult: {
            expensesTax: string,
            incomeTax: string,
            profit: string,
        }
    },
    createdAt: string,
    updatedAt: string,
    closedAt: string | null,
    companyId: number,
    pdfFile: string | null,
    company: {
        id: number,
        name: string,
        curatorId: number | null,
    },
    createdBy: { id: number, username: string },
    closedBy: { id: number, username: string }
}


const TASK_COLOR = {
    'taskAll': '#FAC768FF',
    'taskMine': '#FAC768FF',
    'taskNew': '#85FF85FF',
}



const TasksView = (params: {
    tasksData: Array<TaskDataObjectType> | null,
    tasksType: 'all' | 'my',
    permissions: Set<string>,
    userId: string | number,
    editableButton: null | number,
    setEditableButton: React.Dispatch<React.SetStateAction<number | null>>
    usersData: UsersType,
    companiesData: CompaniesType
}) => {
    const tasksData = params.tasksData;
    const perms = params.permissions;

    let tasksLength = 0;
    if (tasksData) {
        tasksLength = tasksData.length;
    }

    const usersData = params.usersData;
    const companiesData = params.companiesData;

    // @ts-ignore
    return (
        <div className="main__data-body main__task-body contentWindow__body">
            {
                tasksData ?
                        tasksLength === 0 ?
                            <div className='main__tasks-task-wrapper'>
                                <div className='main__tasks-empty-task-container'>
                                    <span>На сегодня задач нет</span>
                                </div>
                            </div>
                        :
                        tasksData.map((td) =>
                            <div key={ `${params.tasksType}Task-${td.id}` } className='main__tasks-task-wrapper'>
                                <div className="main__tasks-task-container">
                                    <div className='main__tasks-task-header'>
                                        <div className='main__tasks-task-creatorName-container'>
                                            <span>
                                                {td.createdBy ? td.createdBy.username : ''}
                                            </span>
                                        </div>
                                        <div className='main__tasks-task-time-container'>
                                            <span>
                                                {formatDateString(td.createdAt)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='main__tasks-task-body'>
                                        <div className='main__task-content-container'>
                                            {
                                                    params.editableButton ? 
                                                        params.editableButton === td.id ?
                                                                <div className='main__task-select-wrapp'>
                                                                    <select defaultValue={td.company ? td.company.id : "null"} name="company" id="taskCompany" className='main__task-select-edit'>
                                                                    <option value="null">Не задан</option>
                                                                {
                                                                    companiesData.map((el) =>
                                                                            <option key={ `comp-${el[0]}` } value={ `${el[0]}` }>{el[1]}</option>
                                                                    ) 
                                                                }
                                                                    </select>
                                                                </div>
                                                            :
                                                            td.company ?
                                                                <a href={ URLS.COMPANY_URL.replace(':id', `${td.company.id}`) }
                                                                    className='main__tasks-task-companyRef'>{td.company.name}
                                                                </a>
                                                                :
                                                                null
                                                        :
                                                        td.company ?
                                                            <a href={ URLS.COMPANY_URL.replace(':id', `${td.company.id}`) }
                                                                className='main__tasks-task-companyRef'>{td.company.name}
                                                            </a>
                                                            :
                                                            null   
                                            }

                                            <div className='main__tasks-task-context-container'>
                                                {
                                                    params.editableButton ? 
                                                        params.editableButton === td.id ?
                                                            <textarea name={`taskContext-${td.id}`} id={`taskContext`} className="main__tasks-textarea"
                                                                onInput={(el: any) => {
                                                                    const textarea = el.target
                                                                    textarea.style.height = 'auto';
                                                                    textarea.style.height = textarea.scrollHeight + 'px';
                                                                    
                                                                }}
                                                            defaultValue={td.context.replaceAll('<br>', '\n')}>
                                                                
                                                            </textarea>
                                                            :
                                                            <span className='main__tasks-task-context'
                                                                dangerouslySetInnerHTML={{__html: td.context}}>
                                                            </span>
                                                        :
                                                        <span className='main__tasks-task-context'
                                                            dangerouslySetInnerHTML={{__html: td.context}}>
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                        {
                                            params.tasksType === 'all' ?
                                                <div className='main__task-edit-container'>
                                                    {
                                                        perms.has('tasks.delete') ? 
                                                        params.editableButton ? 
                                                            params.editableButton === td.id ?
                                                                <div className="main__task-edit-btn-configs">
                                                                    <button className='main__task-save-btn' onClick={() => { 
                                                                            params.setEditableButton(null);
                                                                            const taskCompany: any = document.getElementById('taskCompany');
                                                                            const taskContext: any = document.getElementById('taskContext');
                                                                            const taskUser: any = document.getElementById('taskUser');
                                                                            if (taskCompany && taskContext && taskUser) {                                                                                
                                                                                const taskData = {
                                                                                    "user": taskUser.value,
                                                                                    "company": taskCompany.value,
                                                                                    "taskContext": taskContext.value,
                                                                                }
                                                                                taskPutAPI({ id: td.id, taskData: taskData })
                                                                                    .then((response) => {})
                                                                                    .catch((err) => {
                                                                                        console.log(err);
                                                                                    })
                                                                            }

                                                                        }}>
                                                                        <img className='main__task-edit-ico' src={saveIco} alt="edit" />
                                                                    </button>
                                                                    <button className='main__task-back-btn' onClick={() => { 
                                                                            params.setEditableButton(null);
                                                                        }}>
                                                                        <img className='main__task-edit-ico' src={backIco} alt="edit" />
                                                                    </button>
                                                                </div>
                                                            
                                                                :

                                                                <button className='main__task-edit-btn-unactive'>
                                                                    <img className='main__task-edit-ico' src={editUnactiveIco} alt="edit" />
                                                                </button>
                                                            
                                                            :
                                                            <button className='main__task-edit-btn' onClick={() => { 
                                                                    params.setEditableButton(td.id);
                                                                    setTimeout(() => {
                                                                        const textarea = document.getElementById('taskContext');
                                                                        if (textarea) {
                                                                            textarea.style.height = 'auto';
                                                                            textarea.style.height = textarea.scrollHeight + 'px';
                                                                        }
                                                                    }, 10)
                                                                }}>
                                                                <img className='main__task-edit-ico' src={editIco} alt="edit" />
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                    
                                                :
                                                null
                                        }
                                    </div>
                                    {
                                        params.tasksType === 'all' ?
                                            <div className='main__tasks-task-footer'>
                                                {
                                                    params.editableButton ?
                                                        params.editableButton === td.id ?
                                                            <div className='main__task-select-wrapp-user'>
                                                                <select defaultValue={td.doingBy ? td.doingBy.id : "null"} name="user" id="taskUser" className='main__task-select-edit-user'>
                                                                    <option value="null">Не задан</option>
                                                                    {
                                                                        Object.entries(usersData).map((el) =>
                                                                            <option key={`user-${el[0]}`} value={`${el[0]}`}>{el[1]}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            :
                                                            td.doingBy ?
                                                                <span className='main__tasks-task-doingBy-name'>
                                                                    Выполняет: {td.doingBy.username}
                                                                </span>
                                                                :
                                                                <button
                                                                    className='main__tasks-task-btn main__tasks-task-takeTask'
                                                                    onClick={() => {
                                                                    taskPutAPI({id: td.id, query: 'take', user: params.userId})
                                                                            .then((response) => { })
                                                                            .catch((err) => {
                                                                                console.log(err)
                                                                            })
                                                                    }}
                                                                >Взять задачу</button>                                                
                                                        :
                                                        td.doingBy ?
                                                            <span className='main__tasks-task-doingBy-name'>
                                                                Выполняет: {td.doingBy.username}
                                                            </span>
                                                            :
                                                            <button
                                                                className='main__tasks-task-btn main__tasks-task-takeTask'
                                                                onClick={() => {
                                                                taskPutAPI({id: td.id, query: 'take', user: params.userId})
                                                                        .then((response) => { })
                                                                        .catch((err) => {
                                                                            console.log(err)
                                                                        })
                                                                }}
                                                            >Взять задачу</button>
                                                }
                                                {
                                                    perms.has('tasks.delete') ?
                                                        <button
                                                            className='main__tasks-task-btn main__tasks-task-delTask'
                                                            onClick={() => {
                                                                taskDeleteAPI(td.id)
                                                                    .then((response) => {})
                                                                    .catch((err) => {
                                                                        console.log(err) })
                                                            }}
                                                        >Удалить</button>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            :
                                            <div className='main__tasks-task-footer'>
                                                <button className='main__tasks-task-btn main__tasks-task-takeTask'
                                                    onClick={() => {
                                                                taskPutAPI({id: td.id, query: 'done', user: params.userId})
                                                                    .then((response) => {})
                                                                    .catch((err) => {
                                                                        console.log(err) })
                                                    }}
                                                >Выполнено</button>
                                                <button className='main__tasks-task-btn main__tasks-task-cancelTask'
                                                    onClick={() => {
                                                                taskPutAPI({id: td.id, query: 'deny', user: params.userId})
                                                                    .then((response) => {})
                                                                    .catch((err) => {
                                                                        console.log(err) })
                                                    }}
                                                >Отказаться</button>
                                            </div>
                                    }
                                </div>
                            </div>
                        )
                    :
                    null
            }
        </div>
    )
};


const TasksNew = (params: { usersData: UsersType, companiesData: CompaniesType, createdBy: number }) => {
    const usersData = params.usersData;
    const companiesData = params.companiesData;

    const handleSaveTask = (e: any) => {
        const { user, company, taskContext } = e.target;

        if (taskContext.value.length === 0 || taskContext.value.replaceAll(' ', '').length === 0) {
            addMessageIntoHtml({ elementId: 'taskResMsg', msgColor: "red", message: 'Текст не должен быть пустым', hideElemInSec: 2 })
        } else {
            const reqData = {
                context: taskContext.value.replaceAll('\n', '<br>'),
                user: user.value === 'null' ? null : user.value,
                company: company.value === 'null' ? null : company.value,
                createdBy: params.createdBy,
            }

            taskPostAPI(reqData)
                .then((response) => {
                    if (response.status === 200) {
                        addMessageIntoHtml({ elementId: 'taskResMsg', msgColor: "green", message: 'Задача успешно создана', hideElemInSec: 3 })
                    } else {
                        addMessageIntoHtml({ elementId: 'taskResMsg', msgColor: "red", message: 'Ошибка', hideElemInSec: 3 })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    return (
        <div className="main__data-body main__task-body contentWindow__body">
            <form className="main__task-textarea-container" onSubmit={(e: any) => {
                e.preventDefault();
                handleSaveTask(e);}}>
                <textarea className='main__task-textarea' name='taskContext'></textarea>
                <div className='main__task-options-container'>
                    <div className='main__task-btn-save-wrapper'>
                        <button className='nav__button main__task-btn-save' type='submit'>Сохранить</button>
                    </div>
                    <div className='main__task-select-wrapper'>
                        <span>Предприятие</span>
                        <select name="company" id="taskCompany" className='main__task-select'>
                            <option value="null">Не задан</option>
                            {
                                companiesData.map((el) =>
                                    <option key={ `comp-${el[0]}` } value={ `${el[0]}` }>{el[1]}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className='main__task-select-wrapper'>
                        <span>Пользователь</span>
                        <select name="user" id="taskUser" className='main__task-select'>
                            <option value="null">Не задан</option>
                            {
                                Object.entries(usersData).map((el) =>
                                    <option key={ `user-${el[0]}` } value={ `${el[0]}` }>{el[1]}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
            </form>
            <div className='task__response-msg'>
                <span id='taskResMsg'></span>
            </div>
        </div>
    )
}


const Main = observer(() => {
    const { user, ws } = useContext (Context);

    const [taskOption, setTaskOption] = useState<TaskOptionsType>('taskAll');
    const [taskBodyJSX, setTaskBodyJSX] = useState<JSX.Element>(<></>);

    const [perms, setPerms] = useState<Set<string>>(new Set());

    const [users, setUsers] = useState<UsersType>({});
    const [companies, setCompanies] = useState<CompaniesType>([]);

    const [allTasks, setAllTasks] = useState<Array<TaskDataObjectType> | null>(null);
    const [myTasks, setMyTasks] = useState<Array<TaskDataObjectType> | null>(null);

    const [allCalculations, setAllCalculations] = useState<Array<CalculationDataType>>([]);
    
    const [editableButton, setEditableButton] = useState<number | null>(null);


    useEffect(() => {
        const message = ws.getMsg;
        if (message === WsTriggers.TASK) {
            requestTasks();
            ws.setMessage(null);
        } else if (message === WsTriggers.CALC) {
            requestCalculations();
            ws.setMessage(null);
        }
    }, [ws.getMsg]);

    useEffect(() => {
        requestUsersAndCompanies();
        requestCalculations();
    }, []);

    useEffect(() => {
        if (user.getUser.id) {
            requestTasks();
        }
    }, [user.getUser.id]);

    useEffect(() => {
        
    }, [editableButton])

    const taskOptionToAction = {
        'taskAll': () => {
            setTaskBodyJSX(TasksView({ tasksData: allTasks, tasksType: 'all', permissions: perms, userId: user.getUser.id, editableButton: editableButton, setEditableButton: setEditableButton, companiesData: companies, usersData: users }));
        },
        'taskMine': () => {
            setTaskBodyJSX(TasksView({ tasksData: myTasks, tasksType: 'my', permissions: perms, userId: user.getUser.id, editableButton: editableButton, setEditableButton: setEditableButton, companiesData: companies, usersData: users }));
        },
        'taskNew': () => {
            setTaskBodyJSX(TasksNew({ usersData: users, companiesData: companies, createdBy: user.getUser.id }));
        },
    }


    useEffect(() => {
        setPerms(user.getPermissions.data_);
    }, [user.getPermissions]);

    useEffect(() => {
        taskOptionToAction[taskOption]();
    }, [allTasks, myTasks, editableButton]);

    useEffect(() => {
        taskOptionToAction[taskOption]();
        
        if (taskOption !== 'taskAll') {
            setEditableButton(null);
        }

        const allButtons = document.getElementsByClassName(`main__tasks-button-switch`);
        for (let i = 0; i < allButtons.length; i++) {
            const btn = allButtons[i] as HTMLElement;
            btn.style.boxShadow = '0 0 0.25em #e9e9e9';
            btn.style.color = '#e9e9e9';
        }

        const targetEl = document.getElementById(taskOption) as HTMLElement;
        targetEl.style.boxShadow = `0 0 0.25em ${TASK_COLOR[taskOption]}`;
        targetEl.style.color = TASK_COLOR[taskOption];

    }, [taskOption]);


    const requestTasks = () => {
        taskGetAPI()
            .then((response) => {
                if (response.data) {
                    const currentUserId = user.getUser.id;
                    const newAllTasks: Array<TaskDataObjectType> = [];
                    const newMyTasks: Array<TaskDataObjectType> = [];

                    for (let t = 0; t < response.data.length; t++) {
                        const taskObj: TaskDataObjectType = response.data[t];
                        newAllTasks.push(taskObj);
                        if (taskObj.doingTaskById === currentUserId) {
                            newMyTasks.push(taskObj);
                        }
                    }
                    setAllTasks(newAllTasks);
                    setMyTasks(newMyTasks);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const requestUsersAndCompanies = () => {
        companyGetAPI()
            .then((response) => {
                if (response.data) {
                    const resUsers: UsersType = response.data.users;
                    const resCompanies: Array<{ id: number, name: string }> = response.data.companies;

                    if (user.getUser.username !== 'superuser') {
                        delete resUsers[1]
                    }

                    setUsers(resUsers);
                    setCompanies(resCompanies.map((el) => [el.id, el.name]));
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };

    const requestCalculations = () => {
        calculationGetAPI( 'all', 'calcPending')
            .then((response) => {
                if (response.data) {
                    setAllCalculations(response.data);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const changeCalcStatus = (params: { calcId: number | string, status: 'paid' | 'notPaid' }) => {
        calculationUpdateStatusAPI(params.calcId, params.status, user.getUser.id)
            .then((response) => {
                if (!response.status) {
                    replaceCalcBtnWithLoading({ elId: params.calcId, action: 'show' });
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const replaceCalcBtnWithLoading = (params: { elId: number | string, action: 'hide' | 'show' }) => {
        const elId = params.elId;
        const action = params.action;

        const paidCalcBtn = $(`#paidCalcBtn-${elId}`);
        const notPaidCalcBtn = $(`#notPaidCalcBtn-${elId}`);

        if (action === 'hide') {
            paidCalcBtn.css({ 'display': 'none' });
            notPaidCalcBtn.css({ 'display': 'none' });
            $(`#paidCalcTd-${elId}`).append(`<div class="loader" id="paidCalcLoader-${elId}" style="margin: 0 auto"></div>`)
            $(`#notPaidCalcTd-${elId}`).append(`<div class="loader" id="notPaidCalcLoader-${elId}" style="margin: 0 auto"></div>`)
        } else {
            $(`paidCalcLoader-${elId}`).remove();
            $(`notPaidCalcLoader-${elId}`).remove();
            paidCalcBtn.css({ 'display': 'block' });
            notPaidCalcBtn.css({ 'display': 'block' });
        }

    }

    return (
        <div className='main page-content-container'>
            <NavPanel/>
            <div className='main__body page_content_body'>
                <div className="main__task-deal-history-container">
                    <div className="main__task-container main__data-container contentWindow">
                        <div className="main__data-header contentWindow__header"><span className='contentWindow__name'>Задачи</span></div>
                        <nav className="main__data-nav contentWindow__nav">
                            <button id='taskAll' className='main__tasks-button main__tasks-button-switch nav__button'
                                    onClick={() => { setTaskOption('taskAll') }}
                            >Все задачи({ allTasks ? allTasks.length : 0 })</button>
                            <button id='taskMine' className='main__tasks-button main__tasks-button-switch nav__button'
                                    onClick={() => { setTaskOption('taskMine') }}
                            >Мои задачи({ myTasks ? myTasks.length : 0 })</button>
                            {
                                perms.has('tasks.add') ?
                                    <button id='taskNew' className='main__tasks-button main__tasks-button-switch nav__button'
                                            onClick={() => { setTaskOption('taskNew') }}
                                    >Новая задача</button>
                                    :
                                    null
                            }
                        </nav>
                        { taskBodyJSX }
                    </div>

                    <div className="main__deal-container main__data-container contentWindow">
                        <div className="main__data-header contentWindow__header"><span className='contentWindow__name'>Активные счета</span></div>
                        <nav className="main__data-nav contentWindow__nav"></nav>
                        <div className="main__data-body contentWindow__body main__calc-data-body">
                            <table className='main__calc-table'>
                                <thead className='main__calc-thead'>
                                {
                                    user.getPermissions.has('calculation.update') ?
                                        <tr>
                                            <td className='main__calc-thead-td-1'>Предприятие</td>
                                            <td className='main__calc-thead-td-2'>Дата создания</td>
                                            <td className='main__calc-thead-td-3'>Счёт-фактура</td>
                                            <td className='main__calc-thead-td-4'>Куратор</td>
                                            <td className='main__calc-thead-td-5' colSpan={2}></td>
                                        </tr>
                                        :
                                        <tr>
                                            <td className='main__calc-thead-td-1'>Предприятие</td>
                                            <td className='main__calc-thead-td-2'>Дата создания</td>
                                            <td className='main__calc-thead-td-3'>Счёт-фактура</td>
                                            <td className='main__calc-thead-td-4'>Куратор</td>
                                        </tr>
                                }
                                </thead>
                                <tbody className='main__calc-tbody'>
                                {
                                    allCalculations.length === 0 ?
                                        <tr>
                                            {
                                                user.getPermissions.has('calculation.update') ?
                                                    <td colSpan={6}>Активных счетов нет</td>
                                                    :
                                                    <td colSpan={4}>Активных счетов нет</td>
                                            }

                                        </tr>
                                        :
                                        allCalculations.map((ac) =>
                                                <tr key={`calc-${ac.id}`}>
                                                    <td>
                                                        <a href={`company/${ac.companyId}`}>{ac.company.name}</a>
                                                    </td>
                                                    <td>{formatDateString(`${ac.createdAt}`)}</td>
                                                    <td>{ac.invoice ? `№${ac.invoice}` : '-'}</td>
                                                    <td>{ac.company.curatorId ? users[ac.company.curatorId] : '-'}</td>
                                                    {
                                                        user.getPermissions.has('calculation.update') ?
                                                            <td id={ `paidCalcTd-${ac.id}` }>
                                                                <button 
                                                                    id={`paidCalcBtn-${ac.id}`}
                                                                    className='main__calc-btn'
                                                                        onClick={() => {
                                                                            changeCalcStatus({
                                                                                calcId: ac.id,
                                                                                status: 'paid'
                                                                            })
                                                                            replaceCalcBtnWithLoading({ elId: ac.id, action: 'hide' })
                                                                        }}
                                                                ><img src={paidIco} alt="+"/>
                                                                
                                                                </button>
                                                            </td>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        user.getPermissions.has('calculation.update') ?
                                                        <td id={ `notPaidCalcTd-${ac.id}` }>
                                                                <button 
                                                                    id={`notPaidCalcBtn-${ac.id}`}
                                                                    className='main__calc-btn'
                                                                        onClick={() => {
                                                                            changeCalcStatus({
                                                                                calcId: ac.id,
                                                                                status: 'notPaid'
                                                                            })
                                                                            replaceCalcBtnWithLoading({ elId: ac.id, action: 'hide' })
                                                                        }}
                                                                ><img src={notPaidIco} alt="-"/>
                                                                
                                                                </button>
                                                                
                                                            </td>
                                                            :
                                                            null
                                                    }
                                                </tr>
                                        )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <HistoryContainer historyType={'actionHistory'} containerName={'main'}/>

                </div>
            </div>
        </div>
    );
});


export default Main;
