import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";



export type ParamsType = 'today' | 'yesterday' | 'week' | 'month' | 'all';


export async function suppHistoryGetAPI(id: number | string | undefined, param: ParamsType){
    try {
        if (id) {
            return await $authHost.get(API_ENDPOINTS.SUPPLIER_HISTORY + `/${id}` + '?param=' + param);
        }

    } catch (error: any) {
        return error;
    }
}
