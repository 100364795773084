import React, {useContext, useEffect, useState} from 'react';
import NavPanel from "../components/NavPanel";
import '../styles/SelectedCompany.css';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {
    companyDeleteAPI,
    companyGetAPI,
    companyRepresentativeAddAPI,
    companyRepresentativeDeleteAPI, companyRepresentativeUpdateAPI,
    companyUpdateAPI
} from "../http/companyAPI";
import {usersGetApi} from "../http/userApi";
import {URLS} from "../utils/urls";
import {WsTriggers} from "../WebsocketTriggers";
import CalculationTable, {
    CalculationDataType,
    CalculationResultType,
    gatherTableData
} from "../components/CalculationTable";
import HistoryContainer from "../components/HistoryContainer";
import {calculationPostAPI} from "../http/calculationAPI";
import CalculationsContainer from "../components/CalculationsContainer";
import Popup from "../components/Popup";

const saveIco = require('../assets/icons/selectedComp/saveIco.png');
const saveUnactiveIco = require('../assets/icons/selectedComp/saveUnactiveIco.png');
const editIco = require('../assets/icons/selectedComp/editIco.png');
const editUnactiveIco = require('../assets/icons/selectedComp/editUnactiveIco.png');
const delIco = require('../assets/icons/selectedComp/deleteIco.png');
const backIco = require('../assets/icons/selectedComp/backIco.png');



type RprType = {
    id: number,
    position: string | null,
    fullName: string | null,
    phone: string | null,
    email: string | null,
    companyId: number
};
type NewRprType ={
    position?: string | null | undefined;
    fullName?: string | null | undefined;
    phone?: string | null | undefined;
    email?: string | null | undefined;
    companyId?: number | undefined; }

type CompanyType = {
    id: number;
    name: string;
    code: string | null;
    country: string | null;
    city: string | null;
    region: string | null;
    address: string | null;
    curatorId: number | null;
    curatorName?: string;
    representative: Array<RprType>;
};
type UserType = {
    id: number;
    username: string;
}


const CompanyContentView = (props: {
    compData: CompanyType,
    isEditable: boolean,
    userIdToName: { [key: number]: string }

}): JSX.Element => {
    const isEd = props.isEditable;
    const compData = props.compData;
    const userIdToName = props.userIdToName;


    return (
        <table className='selectedComp__table'>
            <tbody className='selectedComp__tbody'>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.name }</span>
                            :
                            <input className='selectedComp__input' name='name' required={true} type="text"
                                   maxLength={128} minLength={3} autoComplete='off' defaultValue={ compData.name }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Наименование</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.code }</span>
                            :
                            <input className='selectedComp__input' name='code' type="text" maxLength={10}
                                   autoComplete='off' defaultValue={ compData.code ? compData.code : '' }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Код ЄДРПОУ</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.country }</span>
                            :
                            <input className='selectedComp__input' name='country' type="text" maxLength={128}
                                   autoComplete='off' defaultValue={ compData.country ? compData.country : '' }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Страна</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.city }</span>
                            :
                            <input className='selectedComp__input' name='city' type="text" maxLength={128}
                                   autoComplete='off' defaultValue={ compData.city ? compData.city : '' }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Город</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.region }</span>
                            :
                            <input className='selectedComp__input' name='region' type="text" maxLength={128}
                                   autoComplete='off' defaultValue={ compData.region ? compData.region : '' }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Область</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.address }</span>
                            :
                        <input className='selectedComp__input' name='address' type="text" maxLength={128}
                               autoComplete='off' defaultValue={ compData.address ? compData.address : '' }/>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Адрес</td>
            </tr>
            <tr className='selectedComp__tr'>
                <td className='selectedComp__td selectedComp__td-dynamic'>
                    {
                        !isEd ?
                            <span>{ compData.curatorName ? compData.curatorName: '' }</span>
                            :
                            <select className='selectedComp__select' name="curator" defaultValue={
                                compData.curatorId ? compData.curatorId : 'null'
                            }>
                                <option className='selectedComp__option' value="null">Не назначен</option>
                                {
                                    Object.entries(userIdToName).map((u) =>
                                            <option key={`user${u[0]}`} className='selectedComp__option'
                                                    value={`${u[0]}`}>{u[1]}</option>
                                    )
                                }
                            </select>
                    }
                </td>
                <td className='selectedComp__td selectedComp__td-static'>Куратор</td>
            </tr>
            </tbody>
        </table>
    )
}


const RepresentativeContentView = (props: {
    compData: CompanyType,
    permissions: Set<string>,
    editableRprData: RprType | null,
    setEditableRprData: React.Dispatch<React.SetStateAction<RprType | null>>,
    newRprData: NewRprType | null,
    setNewRprData: React.Dispatch<React.SetStateAction<NewRprType | null>>,
    isSaveBtnActive: boolean,
    editableRpr: number | null;
    setEditableRpr: React.Dispatch<React.SetStateAction<number | null>>,
    editingRprData: RprType | null,
    setEditingRprData: React.Dispatch<React.SetStateAction<RprType | null>>,
}) => {
    const rprData: Array<RprType> = props.compData.representative;
    const perms: Set<string> = props.permissions;

    const hasRprAdd = perms.has('representative.add');
    const hasRprUpdate = perms.has('representative.update');


    const inputNewCompData = (e: React.ChangeEvent<HTMLInputElement>) => {

        const {name, value} = e.target;
        props.setNewRprData({
            ...props.newRprData,
            [name]: value
        })
    };

    const inputEditCompData = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.editingRprData === null) {
            const form = e.target.form;
            if (form) {
                const {editingFullName, editingPosition, editingPhone, editingEmail} = form;
                const newData = {
                    editingFullName: editingFullName.value,
                    editingPosition: editingPosition.value,
                    editingPhone: editingPhone.value,
                    editingEmail: editingEmail.value,
                }
                // @ts-ignore
                props.setEditingRprData(newData);

            }
        } else {
            const {name, value} = e.target;
            props.setEditingRprData({
                ...props.editingRprData,
                [name]: value
            })
        }
    }


    const AddNewRprBtn = () => {

        return (
            props.isSaveBtnActive ?
                <td className='selectedComp__rpr-td selectedComp__rpr-new-td'>
                    <button className='selectedComp__icon-button'
                            onClick={(e) => {
                                e.preventDefault();
                                const compId = `${window.location.pathname}`.split('/').pop();
                                const newData = props.newRprData;
                                if (newData && typeof compId === 'string') {
                                    newData['companyId'] = parseInt(compId);
                                    companyRepresentativeAddAPI(newData)
                                        .then((response) => {
                                            if (response.status === 200) {

                                            } else {
                                                alert('Some error')
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                            }}>
                        <img src={saveIco} alt="save" className='selectedComp__icon'/>
                    </button>
                </td>
                :
                <td className='selectedComp__rpr-td selectedComp__rpr-new-td'>
                    <div className='selectedComp__save-button-cover'></div>
                    <button className='selectedComp__icon-button'>
                        <img src={saveUnactiveIco} alt="save" className='selectedComp__icon'/>
                    </button>
                </td>

        )
    }

    const EditRprBtn = (params: { id: number }) => {

        return (
            hasRprUpdate ?
                props.editableRpr === null ?
                    <td className='selectedComp__rpr-td selectedComp__rpr-new-td'>
                        <button className='selectedComp__icon-button'
                            onClick={(e) => {
                                e.preventDefault();
                                props.setEditableRpr(params.id);
                            }}>
                            <img src={editIco} alt="edit" className='selectedComp__icon'/>
                        </button>
                    </td>
                    :
                    props.editableRpr === params.id ?
                        <td className='selectedComp__rpr-td selectedComp__rpr-new-td'>
                            <div className='selectedComp__rpr-edit-tools-container'>
                                <button className='selectedComp__rpr-edit-tools-btn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!props.editingRprData) {
                                            props.setEditableRpr(null);
                                            return
                                        }
                                        // @ts-ignore
                                        const { editingFullName, editingPosition, editingPhone, editingEmail } = props.editingRprData;
                                        const newEditingData = {
                                            fullName: editingFullName.length > 0 ? editingFullName: null,
                                            position: editingPosition.length > 0 ? editingPosition: null,
                                            phone: editingPhone.length > 0 ? editingPhone: null,
                                            email: editingEmail.length > 0 ? editingEmail: null,
                                            id: params.id,
                                            companyId: `${window.location.pathname}`.split('/').pop()
                                        }

                                        companyRepresentativeUpdateAPI(newEditingData.id, newEditingData)
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    props.setEditableRpr(null);
                                                    return
                                                } else {
                                                    alert('Some error')
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })

                                    }}>
                                    <img src={saveIco} alt="save" className='selectedComp__rpr-edit-tools-btnIco'/>
                                </button>
                                <button className='selectedComp__rpr-edit-tools-btn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.setEditableRpr(null);
                                    }}>
                                    <img src={backIco} alt="back" className='selectedComp__rpr-edit-tools-btnIco'/>
                                </button>
                                <button className='selectedComp__rpr-edit-tools-btn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        companyRepresentativeDeleteAPI(params.id)
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    props.setEditableRpr(null);
                                                } else {
                                                    alert('Some error')
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })
                                    }}>
                                    <img src={delIco} alt="del" className='selectedComp__rpr-edit-tools-btnIco'/>
                                </button>
                            </div>
                        </td>
                        :
                        <td className='selectedComp__rpr-td selectedComp__rpr-new-td'>
                            <div className='selectedComp__save-button-cover'></div>
                            <button className='selectedComp__icon-button'>
                                <img src={editUnactiveIco} alt="edit" className='selectedComp__icon'/>
                            </button>
                        </td>
                : null
        )
    }

    return (
        <table className='selectedComp__rpr-table'>
            <tbody className='selectedComp__rpr-tbody'>
            <tr className='selectedComp__rpr-tr'>
                <td className='selectedComp__rpr-td selectedComp__rpr-td-static'>ФИО</td>
                <td className='selectedComp__rpr-td selectedComp__rpr-td-static'>Должность</td>
                    <td className='selectedComp__rpr-td selectedComp__rpr-td-static'>Телефон</td>
                    <td className='selectedComp__rpr-td selectedComp__rpr-td-static'>Почта</td>
                    {hasRprUpdate || hasRprAdd ? <td className='selectedComp__rpr-td selectedComp__rpr-td-static'></td> : null}
                </tr>
                {
                    rprData.map((rpr: RprType) =>
                        props.editableRpr === null ?
                            <tr key={`rpr-${rpr.id}`}
                                className='selectedComp__rpr-tr'>
                                <td className='selectedComp__rpr-td'>{rpr.fullName}</td>
                                <td className='selectedComp__rpr-td'>{rpr.position}</td>
                                <td className='selectedComp__rpr-td'>{rpr.phone}</td>
                                <td className='selectedComp__rpr-td'>{rpr.email}</td>
                                {
                                    hasRprUpdate ?
                                        <EditRprBtn id={rpr.id}/>
                                        :
                                        null
                                }
                            </tr>
                            :
                            props.editableRpr === rpr.id ?
                                <tr key={`rpr-${rpr.id}`}
                                    className='selectedComp__rpr-tr'>
                                    <td className='selectedComp__rpr-td'>
                                        <input
                                            id='editingFullName'
                                            className='selectedComp__rpr-input' name='editingFullName' type="text" required={true}
                                               autoComplete='off' maxLength={128} placeholder='Фамилия Имя Отчество'
                                               defaultValue={rpr.fullName ? rpr.fullName : ''}
                                               onChange={(e) => inputEditCompData(e)}/>
                                    </td>
                                    <td className='selectedComp__rpr-td'>
                                        <input
                                            id='editingPosition'
                                            className='selectedComp__rpr-input' name='editingPosition' type="text"
                                                autoComplete='off' maxLength={128} placeholder='Должность'
                                               defaultValue={rpr.position ? rpr.position : ''}
                                               onChange={(e) => inputEditCompData(e)}/>
                                    </td>
                                    <td className='selectedComp__rpr-td'>
                                        <input
                                            id='editingPhone'
                                            className='selectedComp__rpr-input' name='editingPhone' type="text" autoComplete='off'
                                               maxLength={128} placeholder='+380000000000'
                                               defaultValue={rpr.phone ? rpr.phone : ''}
                                               onChange={(e) => inputEditCompData(e)}/>
                                    </td>
                                    <td className='selectedComp__rpr-td'>
                                        <input
                                            id='editingEmail'
                                            className='selectedComp__rpr-input' name='editingEmail' type="text" autoComplete='off'
                                                maxLength={128} placeholder='email@gmail.com'
                                               defaultValue={rpr.email ? rpr.email : ''}
                                               onChange={(e) => inputEditCompData(e)}/>
                                    </td>

                                    {
                                        hasRprUpdate ?
                                            <EditRprBtn id={rpr.id}/>
                                            :
                                            null
                                    }
                                </tr>
                                :
                                <tr key={`rpr-${rpr.id}`}
                                    className='selectedComp__rpr-tr'>
                                    <td className='selectedComp__rpr-td'>{rpr.fullName}</td>
                                    <td className='selectedComp__rpr-td'>{rpr.position}</td>
                                    <td className='selectedComp__rpr-td'>{rpr.phone}</td>
                                    <td className='selectedComp__rpr-td'>{rpr.email}</td>
                                    {
                                        hasRprUpdate ?
                                            <EditRprBtn id={rpr.id}/>
                                            :
                                            null
                                    }
                                </tr>
                    )
                }
            {
                hasRprAdd ?
                    <tr className='selectedComp__rpr-tr'>
                        <td className='selectedComp__rpr-td'>
                            <input className='selectedComp__rpr-input' name='fullName' type="text" required={true}
                                   autoComplete='off' maxLength={128} placeholder='Фамилия Имя Отчество'
                                   onChange={(e) => inputNewCompData(e)}/>
                        </td>
                        <td className='selectedComp__rpr-td'>
                            <input className='selectedComp__rpr-input' name='position' type="text"
                                   autoComplete='off' maxLength={128} placeholder='Должность'
                                       onChange={(e) => inputNewCompData(e)}/>
                            </td>
                            <td className='selectedComp__rpr-td'>
                                <input className='selectedComp__rpr-input' name='phone' type="text" autoComplete='off'
                                        maxLength={128} placeholder='+380000000000'
                                       onChange={(e) => inputNewCompData(e)}/>
                            </td>
                            <td className='selectedComp__rpr-td'>
                                <input className='selectedComp__rpr-input' name='email' type="text" autoComplete='off'
                                        maxLength={128} placeholder='email@gmail.com'
                                       onChange={(e) => inputNewCompData(e)}/>
                            </td>
                            <AddNewRprBtn/>
                        </tr>
                        :
                        null
                }


            </tbody>
        </table>
    )
}


const SelectedCompany = observer(() => {
    const {user, ws} = useContext(Context);

    useEffect(() => {
        const message: string | null = ws.getMsg;
        if (message !== null) {
            const compId = `${window.location.pathname}`.split('/').pop();
            if (message === WsTriggers.SELECTED_COMPANY + `+${compId}`) {
                requestCompanyData();

                ws.setMessage(null);
            }

        }
    }, [ws.getMsg]);


    const [perms, setPerms] = useState<Set<string>>(new Set());

    const [userIdToName, setUserIdToName] = useState<{ [key: number]: string }>({});
    const [compData, setCompData] = useState<CompanyType | null>(null);

    const [isCompDataEdit, setIsCompDataEdit] = useState<boolean>(false);
    const [isCompDataDelete, setIsCompDataDelete] = useState<boolean>(false);

    const [editableRprData, setEditableRprData] = useState<RprType | null>(null);
    const [newRprData, setNewRprData] = useState<NewRprType | null>(null);
    const [editingRprData, setEditingRprData] = useState<RprType | null>(null);

    const [isSaveBtnActive, setIsSaveBtnActive] = useState<boolean>(false);
    const [editableRpr, setEditableRpr] = useState<number | null>(null);

    const [isPopupActive, setIsPopupActive] = useState<boolean>(false);
    const [headerNamePopup, setHeaderNamePopup] = useState<string>('');
    const [bodyDivElementPopup, setBodyDivElementPopup] = useState<JSX.Element>(<></>);

    const [isSaveLoader, setIsSaveLoader] = useState(false);

    useEffect(() => {
        setPerms(user.getPermissions.data_);
    }, [user.getPermissions]);

    useEffect(() => {
        let isLen = false;

        if (newRprData === null) {
            setIsSaveBtnActive(false)
        } else {
            Object.entries(newRprData).forEach((el: any) => {
                if (el[1].length > 0) {
                    isLen = true
                }
            })
            if (isLen) {
                setIsSaveBtnActive(true);
            } else {
                setIsSaveBtnActive(false);
            }
        }
    }, [newRprData]);


    const requestCompanyData = (params?: { usersMap: { [key: number]: string } }) => {
        const compId = window.location.pathname.split('/').pop();
        const usersMap: { [key: number]: string } = params && params.usersMap ? params.usersMap : userIdToName;

        if (compId) {
            companyGetAPI({companyId: compId})
                .then((response) => {
                    if (response.data) {
                        const newCompanyData: CompanyType = response.data;
                        newCompanyData.curatorName = newCompanyData.curatorId ? usersMap[newCompanyData.curatorId] : '';
                        setCompData(newCompanyData);
                    } else {
                        window.location.replace(URLS.COMPANIES_URL);
                    }
                })
                .catch((err) => { console.log(err) })
        }
    }


    useEffect(() => {
        usersGetApi()
            .then((response) => {
                if (response.data) {
                    const newUsersMap: { [key: number]: string } = {};
                    response.data.forEach((el: UserType) => { newUsersMap[el.id] = el.username });
                    requestCompanyData({ usersMap: newUsersMap });

                    if (user.getUser.username !== 'superuser') {
                        delete newUsersMap[1]
                    }

                    setUserIdToName(newUsersMap);
                }
            })
            .catch((err) => { console.log(err) })
    }, []);


    const handleSaveButton = () => {
        const companyId = window.location.pathname.split('/').pop();

        const resultData: {
            tableData: CalculationDataType,
            tableResult: CalculationResultType,
        } = gatherTableData({ Key: 'baseCalc' })


        if (Object.entries(resultData.tableData).length !== 0) {
            setIsSaveLoader(true);
            calculationPostAPI(resultData, companyId, user.getUser.id)
                .then((response) => {
                    setIsSaveLoader(false);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        return
    }

    return (
        <div className='selectedComp page-content-container'>
            <Popup
                isActive={isPopupActive}
                setIsActive={setIsPopupActive}
                headerName={headerNamePopup}
                bodyDivElement={ bodyDivElementPopup }
            ></Popup>
            <NavPanel/>
            <div className='selectedComp_body page_content_body'>
                <div className='selectedComp_company-data-container'>
                    <div className="selectedComp__comp-container contentWindow">
                        <div className="selectedComp__data-header contentWindow__header">
                            <span className="contentWindow__name">Данные о предприятии</span>
                        </div>
                        <nav className="selectedComp__data-nav contentWindow__nav">
                            {
                                user.getPermissions.has('company.update') ?
                                    !isCompDataEdit ?
                                        <button className='nav__button selectedComp__navBtn selectedComp__navBtn-edit'
                                                onClick={() => {
                                                    setIsCompDataEdit(true)
                                                }}
                                        >Редактировать</button>
                                        :
                                        <div>
                                            <button className='nav__button selectedComp__navBtn selectedComp__save-btn'
                                                    form='compForm' type='submit'
                                            >Сохранить
                                            </button>
                                            <button
                                                className='nav__button selectedComp__navBtn selectedComp__cancel-btn'
                                                onClick={() => {
                                                    setIsCompDataEdit(false)
                                                }}
                                            >Отмена
                                            </button>
                                        </div>
                                    :
                                    null
                            }
                            {
                                user.getPermissions.has('company.delete') ?
                                    !isCompDataDelete ?
                                        <button className='nav__button selectedComp__navBtn selectedComp__navBtn-delete'
                                                onClick={() => {
                                                    setIsCompDataDelete(true)
                                                }}
                                        >Удалить предприятие</button>
                                        :
                                        <div>
                                            <button
                                                className='nav__button selectedComp__navBtn selectedComp__delete-btn'
                                                onClick={() => {
                                                    if (compData) {
                                                        companyDeleteAPI(compData.id)
                                                            .then((response) => {
                                                                if (response && response.status === 200) {
                                                                    window.location.replace(URLS.COMPANIES_URL);
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                console.log(err)
                                                            })
                                                    }
                                                }}
                                            >Удалить
                                            </button>
                                            <button
                                                className='nav__button selectedComp__navBtn selectedComp__cancel-btn'
                                                onClick={() => {
                                                    setIsCompDataDelete(false)
                                                }}
                                            >Отмена
                                            </button>
                                        </div>
                                    :
                                    null
                            }
                        </nav>
                        <div className="selectedComp__data-body">
                            <form id='compForm' onSubmit={(e: any) => {
                                e.preventDefault();
                                const {name, code, country, city, region, address, curator} = e.target;
                                if (compData) {
                                    const newCompanyData = {
                                        id: compData.id,
                                        name: name.value.replaceAll(' ', '').length === 0 ? '---' : name.value,
                                        code: code.value.length === 0 ? null : code.value,
                                        country: country.value.length === 0 ? null : country.value,
                                        city: city.value.length === 0 ? null : city.value,
                                        region: region.value.length === 0 ? null : region.value,
                                        address: address.value.length === 0 ? null : address.value,
                                        curatorId: curator.value === 'null' ? null : parseInt(curator.value),
                                    };

                                    companyUpdateAPI({id: compData.id, data: newCompanyData})
                                        .then((response) => {
                                            if (response.status === 200) {
                                                setIsCompDataEdit(false);
                                            } else {
                                                alert('Some error occurred');
                                                console.log(response);
                                            }
                                        })
                                        .catch((err) => {
                                            alert('Some error occurred')
                                            console.log(err)
                                        })
                                }
                            }}>
                                {
                                    compData ? CompanyContentView({
                                        compData: compData,
                                        isEditable: isCompDataEdit,
                                        userIdToName: userIdToName
                                    }) : null
                                }
                            </form>
                        </div>
                    </div>

                    <div className="selectedComp__rpr-container contentWindow">
                        <div className="selectedComp__data-header contentWindow__header">
                            <span className="contentWindow__name">Контакты</span>
                        </div>
                        <nav className="selectedComp__data-nav contentWindow__nav"></nav>
                        <div className="selectedComp__data-body">
                            <form id='rprForm'>
                                {
                                    compData ? RepresentativeContentView({
                                        compData: compData,
                                        permissions: user.getPermissions,
                                        editableRprData: editableRprData,
                                        setEditableRprData: setEditableRprData,
                                        newRprData: newRprData,
                                        setNewRprData: setNewRprData,
                                        isSaveBtnActive: isSaveBtnActive,
                                        editableRpr: editableRpr,
                                        setEditableRpr: setEditableRpr,
                                        editingRprData: editingRprData,
                                        setEditingRprData: setEditingRprData,
                                    }) : null
                                }
                            </form>
                        </div>
                    </div>
                </div>
                <div className="selectedComp_calculation-cotainer">
                    <div className="selectedComp__calc-container contentWindow">
                        <div className="selectedComp__data-header contentWindow__header">
                            <span className="contentWindow__name">Расчёт</span>
                        </div>
                        <CalculationTable
                            isEditable={true}
                            Key='baseCalc'
                        />
                        <div className="selectedComp__calc-data-body selectedComp__calc-result-container" style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            {
                                perms.has('calculation.add') ?
                                    <div className='selectedComp__calc-save-btn-container'>
                                        {
                                            isSaveLoader ?
                                                <div style={{ position: 'absolute', left: '50%', bottom: '8%' }} className='loader'></div>
                                                :
                                                <button className='nav__button selectedComp__calc-save-btn'
                                                        onClick={() => {
                                                            handleSaveButton();
                                                        }}>
                                                    Сохранить расчёт
                                                </button>
                                        }
                                    </div>
                                    :
                                    <span></span>
                            }
                            <div style={{display: "flex", flexDirection: 'column'}}>
                                <span className='cmpny-baseCalc__calc-result-expenses-tax calc-result-expenses-tax'>Сумма расхода с НДС: 0</span>
                                <span className='cmpny-baseCalc__calc-result-income-tax calc-result-income-tax'>Сумма дохода с НДС: 0</span>
                                <span className='cmpny-baseCalc__calc-result-profit calc-result-profit'>Прибыль: 0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="selectedComp_reports-container">
                    <CalculationsContainer
                        setIsActive={ setIsPopupActive }
                        setHeaderName={ setHeaderNamePopup }
                        setBodyDivElement={ setBodyDivElementPopup }
                    />
                    <HistoryContainer historyType={'companyHistory'} containerName={'selectedComp'}/>
                </div>
            </div>
        </div>
    );
});

export default SelectedCompany;