import React, {useContext, useEffect, useState} from 'react';
import {actHistoryGetAPI, ParamsType} from "../http/actionHistoryAPI";
import {Context} from "../index";
import {usersGetApi} from "../http/userApi";
import {compHistoryGetAPI} from "../http/companyHistoryAPI";
import '../styles/HistoryContainer.css';
import {WsTriggers} from "../WebsocketTriggers";
import {observer} from "mobx-react-lite";
import { suppHistoryGetAPI } from '../http/supplierHistory';


const HIST_BTN = [
    ['today', 'Сегодня'], ['yesterday', 'Вчера'], ['week', 'Неделя'], ['month', 'Месяц'], ['all', 'Всё']
]

const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

type ActHistType = {
    id: number;
    context: string;
    createdAt?: string;
    updatedAt: string;
    userId: number;
    date?: string;
    time?: string;
    userName?: string;
}

const HistoryContainer = observer((props: {
    historyType: 'actionHistory' | 'companyHistory' | 'supplierHistory',
    containerName: string,
}) => {
    const containerName = props.containerName
    const { user, ws } = useContext(Context)

    const REQUESTS = {
        'actionHistory': (option: ParamsType) => actHistoryGetAPI(option),
        'companyHistory': (option: ParamsType) =>
            compHistoryGetAPI(window.location.pathname.split('/').pop(), option),
        'supplierHistory': (option: ParamsType) =>
            suppHistoryGetAPI(window.location.pathname.split('/').pop(), option),
        
    }

    const [selectedHistOpt, setSelectedHistOpt] = useState<ParamsType>('today');
    const [currentDate, setCurrentDate] = useState<string>('');

    const [userDict, setUserDict] = useState<{[key: number]: string} | null>(null);
    const [histDatesList, setHistDatesList] = useState<Array<string>>([]);
    const [histDatesDict, setHistDatesDict] = useState<{[key: string]: Array<ActHistType>} | null>(null);


    useEffect(() => {
        const message = ws.getMsg;
        if (message !== null) {
            if (message === WsTriggers.HISTORY) {
                requestHistoryData();
                ws.setMessage(null);
            }
        }
    }, [ws.getMsg]);


    const requestHistoryData = () => {
        // @ts-ignore
        const currentDate = new Date().toLocaleDateString('ru-RU', options).split(' ');
        setCurrentDate(`${currentDate[0]} ${currentDate[1]} ${currentDate[2]}`);

        REQUESTS[props.historyType](selectedHistOpt).then((response) => {
            if (response.data) {
                const actHistData = response.data;
                const datesArray = [];
                const dataToHistoryDict: any = {};

                for (let i = 0; i < actHistData.length; i++) {                    
                    // @ts-ignore
                    const date = new Date(actHistData[i].createdAt).toLocaleDateString('ru-RU', options).split(' ');
                    const objDate = `${date[0]} ${date[1]} ${date[2]}`;
                    const objTime = `${date[5]}`;
                    const actHistObj = {
                        id: actHistData[i].id,
                        context: actHistData[i].context,
                        updatedAt: actHistData[i].updatedAt,
                        userId: actHistData[i].userId,
                        date: objDate,
                        time: objTime,
                    };
                    if (dataToHistoryDict[objDate]) {
                        dataToHistoryDict[objDate].push(actHistObj)
                    } else {
                        dataToHistoryDict[objDate] = [actHistObj];
                        datesArray.push(objDate)
                    }

                }
                setHistDatesList(datesArray);
                setHistDatesDict(dataToHistoryDict);
                setTimeout(() => {
                    const el = document.getElementById('histBody');
                    if (el) el.scrollTop = el.scrollHeight;
                }, 1)
            }
        }).catch((err) => {
            console.log(err)})
    }


    useEffect(() => {
        usersGetApi()
            .then((response) => {
                if (response.data) {
                    const usersObj: {[key: number]: string} = {};
                    response.data.forEach((el: {id: number, username: string}) => {
                        usersObj[el.id] = el.username
                    })
                    setUserDict(usersObj);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        requestHistoryData()

        const allButtons = document.getElementsByClassName(`${containerName}__history-button`);
        for (let i = 0; i < allButtons.length; i++) {
            const btn = allButtons[i] as HTMLElement;
            btn.style.boxShadow = '0 0 0.25em #e9e9e9';
            btn.style.color = '#e9e9e9';
        }

        const targetEl = document.getElementById(selectedHistOpt) as HTMLElement;
        targetEl.style.boxShadow = '0 0 0.25em #FAC768FF';
        targetEl.style.color = '#FAC768FF'

    }, [selectedHistOpt]);


    return (
        <div className={`${containerName}__history-container ${containerName}__data-container contentWindow`}>
            <div className={`${containerName}__data-header contentWindow__header`}><span
                className={'contentWindow__name'}>История</span></div>
            <nav className={`${containerName}__data-nav contentWindow__nav`}>
                {
                    HIST_BTN.map((b) =>
                        <button key={`keyHist-${b[0]}`} id={`${b[0]}`}
                                className={`${containerName}__history-button nav__button`}
                            //@ts-ignore
                                onClick={() => setSelectedHistOpt(b[0])}
                        >{b[1]}</button>
                    )
                }
            </nav>
            <div id='histBody' className={`historyContainer__data-body historyContainer__data-history-body contentWindow__body`}>
                {
                    histDatesList.length > 0 ?
                        histDatesList.map((hd) =>
                            <div key={hd}
                                 className={`historyContainer__history-item-block`}>
                                <div className={`historyContainer__history-item-date-container`}>
                                    <span className={`historyContainer__history-date`}>{hd}</span>
                                </div>
                                {
                                    histDatesDict && histDatesDict[hd] ? histDatesDict[hd].map((el) =>
                                            <div className={`historyContainer__history-item`} key={`histItm${el.id}`}>
                                                <div className={`historyContainer__history-item-time-container`}>
                                                    <span>{el.time}</span>
                                                    <span>{userDict ? userDict[el.userId] : null}</span>
                                                </div>
                                                <div className={`historyContainer__history-item-context-container`}
                                                     dangerouslySetInnerHTML={{__html: el.context}}/>
                                            </div>
                                        )
                                        :
                                        null
                                }
                            </div>
                        )
                        :
                        <div className={`historyContainer__history-item-block`}>
                            <div className={`historyContainer__history-item-date-container`}>
                                <span className={`historyContainer__history-date`}>{currentDate}</span>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
});

export default HistoryContainer;