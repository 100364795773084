export function setResponseMessage(message: string, type: 'error' | 'success', elementId: string) {
    const msgItem = document.getElementById(elementId);
    const color = { 'error': '#FF5858FF', 'success': '#85FF85FF' }

    if (msgItem) {
        msgItem.style.color = color[type];
        msgItem.innerText = message;
        setTimeout(() => {
            msgItem.innerText = '';
        }, 4000)
    }
}