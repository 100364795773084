import { WS_URL } from "../config";
import { action, makeAutoObservable, runInAction } from "mobx";

export default class WsStore {
    private _message: string | null = null;
    private _socket: WebSocket | null = null;
    private _reconnectInterval: number = 5000; // Интервал попыток переподключения в миллисекундах

    constructor() {
        makeAutoObservable(this, {
            setMessage: action,
        });

        this.connect();
    }

    connect() {
        this._socket = new WebSocket(WS_URL);

        this._socket.addEventListener('open', () => {
            console.log('WebSocket connection opened');
        });

        this._socket.addEventListener('message', (event) => {
            runInAction(() => {
                this._message = event.data;
            });
        });

        this._socket.addEventListener('close', () => {
            console.log('WebSocket connection closed, attempting to reconnect...');
            this.scheduleReconnect();
        });

        this._socket.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
            if (this._socket) {
                this._socket.close();
            }
        });
    }

    scheduleReconnect() {
        setTimeout(() => {
            this.connect();
        }, this._reconnectInterval);
    }

    get getMsg() {
        return this._message;
    }

    setMessage(value: string | null) {
        this._message = value;
    }
}
