import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";


type ActionsHistoryType = {

}

export type ParamsType = 'today' | 'yesterday' | 'week' | 'month' | 'all';


export async function actHistoryGetAPI(param: ParamsType){
    try {
        return await $authHost.get(API_ENDPOINTS.ACT_HISTORY + '?param=' + param);

    } catch (error: any) {
        return error;
    }
}
