import {$authHost} from "./index";
import {API_ENDPOINTS} from "../utils/urls";


export async function pdfGetDefaultAPI(){
    try {
        return await $authHost.get(API_ENDPOINTS.PDF + '/default');

    } catch (error: any) {
        return error;
    }
}

export async function pdfPutAPI(calculationId: string | number){
    try {
        return await $authHost.put(API_ENDPOINTS.PDF + '/calculation' + `/${calculationId}`);

    } catch (error: any) {
        return error;
    }
}

export async function pdfManagerUpdateAPI(data: any){
    try {
        return await $authHost.put(API_ENDPOINTS.PDF + '/settings', data);

    } catch (error: any) {
        return error;
    }
}

export async function pdfUpdateHTMLAPI(params: {htmlData: string}){
    try {
        return await $authHost.put(API_ENDPOINTS.PDF, { htmlData: params.htmlData });

    } catch (error: any) {
        return error;
    }
}