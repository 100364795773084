import {$authHost, $host} from "./index";
import {API_ENDPOINTS, URLS} from "../utils/urls";


type SupplierType = {
    name: string;
    code: string | null;
    country: string | null;
    city: string | null;
    region: string | null;
    address: string | null;
    curatorId: number | null;
}


export async function supplierGetAPI(params?: { supplierId: number | string }){
    try {
        if (params && params.supplierId) {
            return await $authHost.get(API_ENDPOINTS.SUPPLIER + '/' + params.supplierId);
        } else {
            return await $authHost.get(API_ENDPOINTS.SUPPLIER);
        }

    } catch (error: any) {
        return error;
    }
}

export async function supplierAddNewAPI(data: any){
    try {
        return await $authHost.post(API_ENDPOINTS.SUPPLIER, data);

    } catch (error: any) {
        return error;
    }
}

export async function supplierSearchAPI(query: string) {
    try {
        return await $authHost.get(API_ENDPOINTS.SUPPLIER + `/search${query}`);

    } catch (error: any) {
        return error;
    }
}

export async function supplierUpdateAPI(props: {id: string | number, data: any}){
    try {
        return await $authHost.put(API_ENDPOINTS.SUPPLIER + `/` + props.id, props.data);

    } catch (error: any) {
        return error;
    }
}

export async function supplierDeleteAPI(id: string | number){
    try {
        return await $authHost.delete(API_ENDPOINTS.SUPPLIER + `/` + id);

    } catch (error: any) {
        return error;
    }
}

export async function supplierRepresentativeAddAPI(data: any){
    try {
        return await $authHost.post(API_ENDPOINTS.SUPPLIER_REPR, data);

    } catch (error: any) {
        return error;
    }
}

export async function supplierRepresentativeDeleteAPI(id: string | number){
    try {
        return await $authHost.delete(API_ENDPOINTS.SUPPLIER_REPR + `/` + id);

    } catch (error: any) {
        return error;
    }
}

export async function supplierRepresentativeUpdateAPI(id: string | number, data: any){
    try {
        return await $authHost.put(API_ENDPOINTS.SUPPLIER_REPR + `/` + id, data);

    } catch (error: any) {
        return error;
    }
}
