import React, {useEffect, useState} from 'react';
import '../styles/Popup.css';


const Popup = (props: {
    isActive: boolean
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
    headerName?: string
    bodyDivElement: JSX.Element

}) => {
    const [headerName, setHeaderName] = useState(<span className="popup__header-name">{props.headerName}</span>);

    useEffect(() => {
        if (props.isActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.isActive]);


    useEffect(() => {
        if (!props.headerName) {
            setHeaderName(<span className="popup__header-name" style={{opacity: '0'}}>_</span>);
        } else {
            setHeaderName(<span className="popup__header-name">{props.headerName}</span>);
        }
    }, []);

    return (
        props.isActive ?
            <div id="popup" className='popupBack'
                // CLOSE ON BACK CLICK
                //  onClick={(el) => {
                //      const elementId = (el.target as HTMLElement).id;
                // if (elementId === 'popup') props.setIsActive(false);
                // }}
            >
                <div className='popup__container'>
                    <div className='popup__header'>
                        { headerName }
                        <button className="popup__header-close" onClick={() => props.setIsActive(false)}>
                            &#10006;
                        </button>
                    </div>
                    {
                        props.bodyDivElement
                    }
                </div>
            </div>
            :
            <></>
    );
};

export default Popup;

