import {$authHost} from "./index";
import {API_ENDPOINTS} from "../utils/urls";


type TaskType = {
    context: string;
    user: string | number | null;
    company: string | number | null;
    createdBy: number;
}


export async function taskGetAPI(){
    try {
        return await $authHost.get(API_ENDPOINTS.TASK);

    } catch (error: any) {
        return error;
    }
}

export async function taskPostAPI(data: TaskType){
    try {
        return await $authHost.post(API_ENDPOINTS.TASK, data);

    } catch (error: any) {
        return error;
    }
}

export async function taskPutAPI(
    params: {
        id: number | string,
        query?: 'take' | 'done' | 'deny',
        user?: string | number,
        taskData?: any,
    }
) {
    try {
        if (params.taskData) {
            return await $authHost.put(API_ENDPOINTS.TASK + '/' + params.id, params.taskData)
        } else {
            return await $authHost.put(API_ENDPOINTS.TASK + '/' + params.id + `?type=${params.query}&user=${params.user}`);
        }
        

    } catch (error: any) {
        return error;
    }
}

export async function taskDeleteAPI(id: number | string){
    try {
        return await $authHost.delete(API_ENDPOINTS.TASK + '/' + id);

    } catch (error: any) {
        return error;
    }
}