const jwt_decode = require("jwt-decode");


export function getUserHighestRole(): 'none' | 'auth' | 'admin' {
    const token: string | null = localStorage.getItem('token');

    if (!token) {
        return 'none';
    }

    const role = jwt_decode.jwtDecode(token).role;

    switch (role) {
        case 'SUPERUSER':
            return 'admin'
        case 'USER':
            return 'auth'
    }

    localStorage.removeItem('token');
    return 'none';
}
